import Button from '../utils/Button/Button';
import Modal from '../utils/Modal/Modal';
import Dots from '../utils/Dots/Dots';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import FormField from '../FormField/FormField';
import PasswordContainer from '../utils/PasswordContainer/PasswordContainer';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { deleteUser } from 'firebase/auth';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useLogout } from '../../hooks/useLogout';
import type { User } from 'firebase/auth';
import { AlertParams } from '../utils/Alert/Alert';
import { FirebaseError } from 'firebase/app';

type DeleteAccountProps = {
  refreshCredentials: (password: string) => Promise<void>;
  user: User;
};

const DeleteAccount = ({ refreshCredentials, user }: DeleteAccountProps) => {
  const { handleSettingsError } = useErrorHandling();
  const { logout } = useLogout();

  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmPasswordAlert, setDeleteConfirmPasswordAlert] = useState<AlertParams | null>(
    null,
  );
  const [deletePending, setDeletePending] = useState(false);
  const [deleteConfirmPassword, setDeleteConfirmPassword] = useState('');
  const [showDeleteConfirmPassword, setShowDeleteConfirmPassword] = useState(false);

  // Clear any lingering data and alerts when modal is open/closed
  useEffect(() => {
    setDeleteConfirmPassword('');
    setDeleteConfirmPasswordAlert(null);
    setShowDeleteConfirmPassword(false);
  }, [showModal]);

  // Ensure form is validated before calling form submission function
  const isModalFormValid = () => {
    let valid = true;
    let inputFocused = false;

    const deleteConfirmPasswordInput = document.querySelector(
      'input[name="deleteConfirmPassword"]',
    ) as HTMLInputElement;

    // Check for blank field
    if (deleteConfirmPasswordInput.value.trim().length === 0) {
      if (!inputFocused) {
        deleteConfirmPasswordInput.focus();
        inputFocused = true;
      }
      setDeleteConfirmPasswordAlert({
        message: 'Please enter a password.',
        kind: 'error',
      });
      deleteConfirmPasswordInput.classList.add('error');
      valid = false;
    }
    return valid;
  };

  // Deletes the user account, as well as associated data including user document, and any created prescribers
  const deleteAccount = async () => {
    // Gather a reference to all of the user's prescribers
    const presRef = collection(db, 'prescribers');
    const presQuery = query(presRef, where('uid', '==', user.uid));
    const docsSnap = await getDocs(presQuery);

    try {
      // Delete all of the user's prescribers
      docsSnap.forEach((doc) => deleteDoc(doc.ref));
      // Delete the user's document in firestore
      await deleteDoc(doc(db, 'users', user.uid));
      // Delete the user iteself from firebase auth
      await deleteUser(user);
      // Finally, logout
      logout();
      toast.success('Account deleted');
    } catch (error) {
      toast.error('An error occurred while deleting account');
    }
  };

  // Combine all actions into a single function that handles credentials and delete operations
  const performDeleteAccount = async () => {
    setDeletePending(true);
    let reauthenticated = false;

    // First refresh credentials (even if the user recently signed in)
    try {
      await refreshCredentials(deleteConfirmPassword);
      reauthenticated = true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleSettingsError(error.code, setDeleteConfirmPasswordAlert);
      } else {
        console.error(error);
      }
      setDeletePending(false);
    }
    // Proceed with operations if the user provides correct sign in detais;
    if (reauthenticated) {
      deleteAccount();
      setDeletePending(false);
    }
  };

  return (
    <>
      <div className="delete-account">
        <div className="form-title form-title--delete">Delete account</div>
        <p className="warning">Once you delete your account, it is permanent. Please be sure.</p>
        <Button onClick={() => setShowModal(true)} design="delete">
          Delete account
        </Button>
      </div>

      {showModal && (
        <Modal
          title="Delete account"
          closeModal={() => setShowModal(false)}
          kind="delete"
          errorMessage="This action is permanent and cannot be undone."
        >
          <div className="prescriber-display">
            <div className="prescriber-label">Please enter your password to continue</div>
          </div>
          <form
            className="Login__form"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              if (isModalFormValid()) {
                performDeleteAccount();
              }
            }}
          >
            <PasswordContainer
              showPassword={showDeleteConfirmPassword}
              handleClick={() => setShowDeleteConfirmPassword((prevState) => !prevState)}
            >
              <FormField
                id="current-password"
                type={`${showDeleteConfirmPassword ? 'text' : 'password'}`}
                name="deleteConfirmPassword"
                label="Password"
                value={deleteConfirmPassword}
                onChange={(event) => setDeleteConfirmPassword(event.target.value)}
                className="auth-field form-field"
                alert={deleteConfirmPasswordAlert}
                required
                autoComplete="current-password"
              />
            </PasswordContainer>

            <div className="Modal__buttons">
              <Button design="secondary" classLabel="cancel" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button type="submit" design="delete">
                {deletePending ? <Dots color="white" /> : 'Delete'}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DeleteAccount;
