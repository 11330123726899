import { useEffect } from 'react';
import { ZodError } from 'zod';
import { FirestoreError } from 'firebase/firestore';
import toast from 'react-hot-toast';
import Bugsnag from '@bugsnag/js';

/**
 * General use fetch error hook that provides generalised error messages to users after attempting
 * GET fetch operations.
 */
export const useHandleFetchError = (error: unknown) => {
  useEffect(() => {
    if (!error) return;

    if (error instanceof Error) {
      Bugsnag.notify(error);

      switch (true) {
        case error instanceof ZodError:
          toast.error(
            'There was a problem processing the data. Please refresh or try again later.',
          );
          break;

        case error instanceof FirestoreError:
          toast.error('A database error occurred. Please try again later.');
          break;

        default:
          // All errors reaching this point should be of generic type `Error`
          toast.error('An unexpected error occurred. Please try again.');
          break;
      }
    } else {
      // Report more context about the non-Error
      const errorDetails = typeof error === 'object' ? JSON.stringify(error) : String(error);
      Bugsnag.notify(`Unknown non-Error instance triggered during fetch: ${errorDetails}`);
      toast.error('An unknown error occurred. Please try again.');
    }
  }, [error]);
};
