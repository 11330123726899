import { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useAuthContext } from './useAuthContext';
import { db, auth } from '../firebase/config';
import { doc, setDoc } from 'firebase/firestore';
import { useErrorHandling } from './useErrorHandling';
import { FirebaseError } from 'firebase/app';

// Custom hook to handle user signing in
export const useSignup = () => {
  // Error can be passed to UI elements as needed
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { handleLoginSignupError } = useErrorHandling();

  const signup = async (
    email: string,
    password: string,
    displayName: string,
    setFirstSignIn: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setError(null);
    setIsPending(true);

    try {
      // Firebase function to sign up user. Once resolved, confirm with a context state change
      const res = await createUserWithEmailAndPassword(auth, email, password);

      // Add display name to user
      await updateProfile(res.user, {
        displayName: displayName,
      });

      await setDoc(doc(db, 'users', res.user.uid), {
        // Any additional user data here
        displayName: displayName,
        scripts: [],
        favourites: [],
      });

      setIsPending(false);
      dispatch({ type: 'LOGIN', payload: res.user });
      // Ensure a first time user receives any relevant first time user features (like email verification)
      setFirstSignIn(true);
    } catch (err) {
      setIsPending(false);
      if (err instanceof FirebaseError) {
        handleLoginSignupError(err.code, setError);
      } else {
        console.error(error);
      }
    }
  };
  return { error, isPending, signup };
};
