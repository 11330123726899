import FormField from '../FormField/FormField';
import { useState } from 'react';
import Dots from '../utils/Dots/Dots';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { StyledResetPassword } from './ResetPassword.styled';
import { useNavigate } from 'react-router-dom';
import Button from '../utils/Button/Button';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { AlertParams } from '../utils/Alert/Alert';
import { FirebaseError } from 'firebase/app';
import toast from 'react-hot-toast';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { handleSettingsError } = useErrorHandling();

  const [email, setEmail] = useState('');
  const [emailAlert, setEmailAlert] = useState<AlertParams | null>(null);
  const [isPending, setIsPending] = useState(false);

  // Ensure form is validated before calling form submission function
  const isFormValid = () => {
    let valid = true;
    const emailInput: HTMLInputElement | null = document.querySelector('input[name="email"]');

    if (!emailInput) {
      return;
    }

    // Check for blank field
    if (email.trim().length === 0) {
      emailInput.focus();
      setEmailAlert({
        message: 'Please enter an email address.',
        kind: 'error',
      });
      emailInput.classList.add('error');
      valid = false;
    }
    return valid;
  };

  // Attempt to send password link to email provided, and handle any errors that occur
  const resetPassword = async () => {
    setIsPending(true);
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setIsPending(false);
      toast.success('Reset link has been sent');
      navigate('/login');
    } catch (error) {
      setIsPending(false);
      if (error instanceof FirebaseError) {
        handleSettingsError(error.code, setEmailAlert);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <StyledResetPassword className="Login">
      <div className="Login__container">
        <h2 className="Login__title">Reset your password</h2>

        <form
          className="Login__form"
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            if (isFormValid()) {
              resetPassword();
            }
          }}
        >
          <FormField
            type="email"
            name="email"
            label="Enter the email address associated with your account and we'll send you a password reset link."
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="auth-field form-field"
            alert={emailAlert}
            required
            autoComplete="username"
          />

          <Button classLabel="Login__btn" type="submit">
            {isPending ? <Dots color="white" /> : 'Send password reset email'}
          </Button>
        </form>
      </div>
    </StyledResetPassword>
  );
};

export default ResetPassword;
