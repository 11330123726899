import { useCallback } from 'react';
import { SetAlertFunc } from '../components/utils/Alert/Alert';

// Define generalised form field validation functions and features here. These may be destrcutured and ised as required in form components.

// UI error/success functions
const applyErrorStyling = (element: HTMLElement) => {
  element.classList.add('error');
  element.classList.remove('success');

  // Remove the tick icon
  const tick = element.parentNode?.querySelector('.tickCircle');
  tick?.classList.remove('show');
  tick?.classList.add('hide');
};

const applySuccessStyling = (element: HTMLElement) => {
  element.classList.remove('error');
  element.classList.add('success');

  // Add the tick icon
  const tick = element.parentNode?.querySelector('.tickCircle');
  tick?.classList.remove('hide');
  tick?.classList.add('show');
};

export const useInputValidation = () => {
  // ! useCallbacks are absolutely necessary as these functions are most commonly used in useEffect hooks

  // Show positive UI feedback on a form field. Used in a conditional that validates form field
  const positiveValidationUI = useCallback(
    (setAlertFunc: SetAlertFunc, field: HTMLInputElement) => {
      applySuccessStyling(field);
      setAlertFunc((prevAlerts) => ({
        ...prevAlerts,
        [field.name]: null,
      }));
    },
    [],
  );

  // Show negative UI feedback on a form field. Used in a conditional that validates form field
  const negativeValidationUI = useCallback(
    (setAlertFunc: SetAlertFunc, alertMsg: string, field: HTMLInputElement) => {
      applyErrorStyling(field);
      setAlertFunc((prevAlerts) => ({
        ...prevAlerts,
        [field.name]: {
          message: alertMsg,
          kind: 'error',
        },
      }));
    },
    [],
  );

  // Combination of the positive and negative UI functions but including the most common case validation conditional of assessing for empty value. Used to add validation to required fields in a form.
  const validateRequiredField = useCallback(
    (setAlertFunc: SetAlertFunc, field: HTMLInputElement) => {
      // Validate full name here
      if (field.value.trim().length === 0) {
        setAlertFunc((prevAlerts) => ({
          ...prevAlerts,
          [field.name]: {
            message: 'This field cannot be left blank',
            kind: 'error',
          },
        }));
        applyErrorStyling(field);
      } else {
        applySuccessStyling(field);
        setAlertFunc((prevAlerts) => ({
          ...prevAlerts,
          [field.name]: null,
        }));
      }
    },
    [],
  );

  const removeAllValidation = useCallback(
    (element: HTMLInputElement, setAlertFunc: SetAlertFunc) => {
      element.classList.remove('error');
      element.classList.remove('success');
      const tick = element.parentNode?.querySelector('.tickCircle');
      tick?.classList.remove('show');
      tick?.classList.add('hide');
      setAlertFunc((prevAlerts) => ({
        ...prevAlerts,
        [element.name]: null,
      }));
    },
    [],
  );

  return { positiveValidationUI, negativeValidationUI, validateRequiredField, removeAllValidation };
};
