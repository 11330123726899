import Button from '../utils/Button/Button';
import FormField from '../FormField/FormField';
import { useState, useEffect } from 'react';
import Dots from '../utils/Dots/Dots';
import { updateProfile } from 'firebase/auth';
import type { User } from 'firebase/auth';
import toast from 'react-hot-toast';

type ChangeDisplayNameProps = {
  user: User;
};

const ChangeDisplayName = ({ user }: ChangeDisplayNameProps) => {
  const [displayName, setDisplayName] = useState('');
  const [namePending, setNamePending] = useState(false);

  // Ensure the current display name is made visible to user
  useEffect(() => {
    setDisplayName(user.displayName ?? '');
  }, [user]);

  // Can perform this update without refreshing credentials
  const updateName = async () => {
    setNamePending(true);
    try {
      await updateProfile(user, {
        displayName: displayName,
      });

      setNamePending(false);
      toast.success('Display name updated');
    } catch (error) {
      setNamePending(false);
      toast.error('An error occurred while changing name');
    }
  };

  return (
    <form
      className="displayName-form"
      onSubmit={(event) => {
        event.preventDefault();
        updateName();
      }}
    >
      <div className="form-title">Change display name</div>
      <FormField
        type="text"
        name="displayName"
        label="Display name"
        value={displayName}
        onChange={(event) => setDisplayName(event.target.value)}
      />
      <input type="text" className="hidden" />
      <Button type="submit">{namePending ? <Dots color="white" /> : 'Update display name'}</Button>
    </form>
  );
};

export default ChangeDisplayName;
