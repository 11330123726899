import Alert, { AlertParams } from '../utils/Alert/Alert';
import { StyledFormField } from './FormField.styled';

type FormFieldProps = {
  label: string;
  alert?: AlertParams | null;
  subAlert?: AlertParams | null;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FormField = ({ label, alert, subAlert, ...props }: FormFieldProps) => {
  const { type, id, name, className, checked } = props;

  // Used in conjunction with aria-describedby. Alert IDs will prefer ID, otherwise default to name
  const alertID = `${id ? id + '-alert' : name + '-alert'}`;

  // Check if there is either an alert or subalert present. If so, aria-describedby attribute must be present
  const ariaDescribe = alert || subAlert ? { 'aria-describedby': alertID } : {};

  return (
    <StyledFormField className={className}>
      <div className="container">
        <label className="form-field__label" htmlFor={id ? id : name}>
          <span className="label-text">{label}</span>
          <input id={id ? id : name} {...ariaDescribe} {...props} />
          {/* Optional checkbox element */}
          {type === 'checkbox' && (
            <span className={`checkmark ${checked ? 'show' : 'hide'}`}></span>
          )}
          {/* Optional tick element */}
          <svg xmlns="http://www.w3.org/2000/svg" className="tickCircle hide" viewBox="0 0 512 512">
            <path
              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
              fill="#fff"
              stroke="#0a7e00"
              strokeMiterlimit="10"
              strokeWidth="25"
            />
            <path
              fill="#fff"
              stroke="#0a7e00"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="27"
              d="M352 176L217.6 336 160 272"
            />
          </svg>
        </label>
      </div>

      {/* Pass the alert prop as an object only where alerts are required (default null) that contains both the message to display in the alert, and the type of alert, e.g. error, warning, success */}
      {alert && <Alert kind={alert.kind} message={alert.message} id={alertID} />}

      {/* SubAlert is used for a second alert either applying to the same field, or for an adjacent related field where it makes more sense to group alerts */}
      {subAlert && (
        <Alert
          kind={subAlert.kind}
          message={subAlert.message}
          id={alertID}
          isSubAlert={Boolean(subAlert)}
        />
      )}
    </StyledFormField>
  );
};

export default FormField;
