import { StyledSettings } from './Settings.styled';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import type { User } from 'firebase/auth';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import ChangeDisplayName from './ChangeDisplayName';
import DeleteAccount from './DeleteAccount';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import { Helmet } from 'react-helmet-async';

type SettingsProps = {
  user: User;
};

const Settings = ({ user }: SettingsProps) => {
  // Used to reauthenticate a user. This avoids an error when performing sensitive account functions like delete or email update
  const refreshCredentials = async (password: string) => {
    // Must be called once the user has entered their password, else it will just error
    if (!user.email) {
      console.error('No user email found');
      return;
    }
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);
  };

  return (
    <>
      <Helmet>
        <title>Settings · OptomRx</title>
        <meta
          name="description"
          content="Adjust your basic profile details, verify your email, or delete your account."
        />
        <link rel="canonical" href="/settings" />
      </Helmet>
      <ContentContainer>
        <StyledSettings className="Settings">
          <PageHeader title="Settings" description="Adjust basic profile and account settings" />
          <div className="Settings-container">
            {user.emailVerified ? (
              <>
                <ChangeDisplayName user={user} />
                <ChangeEmail refreshCredentials={refreshCredentials} verified={true} user={user} />
                <ChangePassword refreshCredentials={refreshCredentials} user={user} />
              </>
            ) : (
              <ChangeEmail refreshCredentials={refreshCredentials} verified={false} user={user} />
            )}
            <DeleteAccount refreshCredentials={refreshCredentials} user={user} />
          </div>
        </StyledSettings>
      </ContentContainer>
    </>
  );
};

export default Settings;
