import PBSData from '../../pbs/drugAutocompleteData.json';
import { useEffect, useState, useRef, useCallback } from 'react';
import { StyledDrugAutocomplete } from './DrugAutocomplete.styled';
import FormField from '../FormField/FormField';
import Tooltip from '../utils/Tooltip/Tooltip';
import { DrugAutocompleteOption, DrugData } from '../../types/firestore';
import { AlertState, SetAlertFunc } from '../utils/Alert/Alert';
import { z } from 'zod';

type DrugAutocompleteProps = {
  data: DrugData;
  setData: React.Dispatch<React.SetStateAction<DrugData>>;
  showTooltip: boolean;
  tooltipText: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggle: <T>(
    setFunc: React.Dispatch<React.SetStateAction<T>>,
    data: T,
    boolToChange: string,
  ) => void;
  alerts: AlertState;
  setAlerts: SetAlertFunc;
  fetchDrug: (itemCode: string) => Promise<void>;
};

const drugItemDatasetSchema = z.object({
  code: z.string(),
  activeIngredient: z.string(),
  brandName: z.string().optional(),
  compounded: z.string().optional(),
});

const DrugAutocomplete = ({
  data,
  setData,
  handleChange,
  toggle,
  alerts,
  setAlerts,
  fetchDrug,
  showTooltip,
  tooltipText,
}: DrugAutocompleteProps) => {
  // useRef allows us to store the equivalent of a 'global' component variable without losing data on re-render, but avoiding the async problems that can arise with state
  const currentFocus = useRef(-1);
  // Controls the UI state of the collapsed input fields
  const [expand, setExpand] = useState(false);
  const itemsListRef = useRef<HTMLDivElement>(null);

  const showSuccessClass = (element: HTMLElement) => {
    element.classList.remove('error');
    element.classList.add('success');
  };

  // Toggle the checkboxes in the form on enter keypress, but don't submit the form
  const changeOnEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
    setFunc: React.Dispatch<React.SetStateAction<DrugData>>,
    data: DrugData,
  ) => {
    // If the enter key is pressed
    if (event.key === 'Enter') {
      event.preventDefault();
      toggle(setFunc, data, event.currentTarget.name);
    }
  };

  // Hide the items list but don't alter the items on the list
  const hideItemsList = () => {
    // Check for null in cases where component is dismounting/ed
    if (document.querySelector('.items-list')) {
      document.querySelector('.items-list')?.classList.add('hide');
      document.querySelector('.items-list')?.classList.remove('show-list');
    }
  };

  // Show the items list but don't alter the items on the list
  const showItemsList = () => {
    document.querySelector('.items-list')?.classList.remove('hide');
    document.querySelector('.items-list')?.classList.add('show-list');
  };

  // Remove all items within the list, rather than the list itself
  const removeList = () => {
    // Must reset focus here to avoid starting halfway down a list on first arrow key press
    currentFocus.current = -1;
    document.querySelectorAll('.item').forEach((item) => {
      item.remove();
    });
  };

  // Used to set verification status of drug to false. Useful in scenarios where user is modifying auto-fill data and thus inputs can no longer be trusted
  const removeVerification = () => {
    setData((prevData) => ({
      ...prevData,
      verified: false,
    }));
  };

  // Capture the selection made in the items list via event delegation
  // All child spans have pointer events set to none, so the parent item element will ALWAYS capture the even here
  const clickSuggestion = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const dataset = drugItemDatasetSchema.parse(target.dataset);
      let toBeCompounded = false;
      // Check if the drug requires compounding (atropine only at this stage)
      if (dataset.compounded) {
        toBeCompounded = true;
      }

      // Set state on click - do NOT set input.value as this will not work as intended. Always adjust state and have input.value set to state
      // @ts-expect-error This will be fixed in upcoming Mantine UI integration
      setData((prevData) => ({
        ...prevData,
        activeIngredient: dataset.activeIngredient,
        brandName: dataset.brandName,
        itemCode: dataset.code,
        compounded: toBeCompounded,
        verified: true,
      }));
      removeList();
      setExpand(true);
      fetchDrug(dataset.code);

      // Remove errors
      showSuccessClass(document.querySelector('#activeIngredient') as HTMLInputElement);
      setAlerts((prevAlerts) => ({
        ...prevAlerts,
        activeIngredient: null,
        brandName: null,
      }));

      // Finally, set focus to next type-able field (currently dosage)
      const dosageInput = document.querySelector('[name="dosage"]') as HTMLInputElement;
      dosageInput.focus();
    },
    [setAlerts, setData, fetchDrug],
  );

  // Given a string, use the current search text and regex to bold the segment of text being searched for (using HTML)
  const boldLetters = (string: string) => {
    const inputElement: HTMLInputElement | null = document.querySelector('#activeIngredient');
    const currentSearchTerm = inputElement?.value || '';
    const regexFirst = new RegExp(`^${currentSearchTerm}`, 'i');
    // Must add capturing group to this regex for later extraction
    const regexSecond = new RegExp(`\\+ (${currentSearchTerm})`, 'i');

    const firstMatch = string.match(regexFirst);
    const secondMatch = string.match(regexSecond);

    // If there is a regex match, the match function returns an array, otherwise is null
    if (firstMatch) {
      // Oth index returns the substring that matches
      const startIndex = firstMatch.index ?? 0;
      return `<strong class="item-bold item-click">${string.substring(
        startIndex,
        startIndex + firstMatch[0].length,
      )}</strong>${string.substring(startIndex + firstMatch[0].length)}`;
    } else if (secondMatch) {
      // Use a capturing group in regexSecond, which will appear as index 1 in the match array
      const startIndex = secondMatch.index ?? 0;
      return `${string.substring(
        0,
        startIndex,
      )}<strong class="item-bold item-click">${string.substring(
        startIndex + 2,
        startIndex + 2 + secondMatch[1].length,
      )}</strong>${string.substring(startIndex + 2 + secondMatch[1].length)}`;
    } else {
      // If no matches, return all non-bold
      return string;
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;

    // Reset the search results when the user clears the field
    if (!searchText) {
      createList([]);
      return;
    }

    // Two regex to match search text in different parts of the string. Split-up to allow custom ordering of matches in final UI list
    let regexFirst: RegExp | null = null;
    let regexSecond: RegExp | null = null;

    try {
      regexFirst = new RegExp(`^${searchText}`, 'i');
      regexSecond = new RegExp(`\\+ ${searchText}`, 'i');
    } catch (error) {
      // Invalid regex, usually due to special characters from user input.
      createList([]);
      return;
    }

    // Match first at the start of a string (e.g. 'tim' matches 'timolol' but not 'latanoprost + timolol')
    const firstMatches = PBSData.filter(
      (drug) =>
        drug['brand-name'].some((name) => regexFirst?.test(name)) ||
        regexFirst?.test(drug['tpuu-or-mpp-pt']),
    );
    // Match a second drug (e.g. 'tim' matches 'latanoprost + timolol' but not 'timolol'). These are lower priority and should be displayed second in a list
    const secondMatches = PBSData.filter(
      (drug) =>
        (drug['brand-name'].some((name) => regexSecond?.test(name)) ||
          regexSecond?.test(drug['tpuu-or-mpp-pt'])) &&
        !firstMatches.includes(drug),
    );

    // Combine all results into a single array
    const matches = firstMatches.concat(secondMatches);
    createList(matches);
  };

  // Creates list of autocomplete items using an array of relevant suggestions (matchArr)
  const createList = useCallback((matchArr: DrugAutocompleteOption[]) => {
    // First remove any lists present to ensure the list if refreshed on each new input
    removeList();
    // Also ensure the list does not have a hide class active
    showItemsList();
    const itemsList = document.querySelector('.items-list');

    // Limit the autocomplete list to a specified amount of items
    const maxListItems = 6;

    for (let i = 0; i < maxListItems; i++) {
      // Don't attempt to iterate beyond the number of matches returned, which may be less than maxListItems
      if (i >= matchArr.length) {
        break;
      }

      const match = matchArr[i];

      // Operation in template literal is capitalising first letter
      const boldActiveName = boldLetters(
        `${match['tpuu-or-mpp-pt'][0].toUpperCase() + match['tpuu-or-mpp-pt'].substring(1)}`,
      );
      const boldBrandName = boldLetters(`${match['brand-name']}`);

      const item = document.createElement('div');
      // Using spans will allow alternate styling of active ingredient and brand name if desired
      item.innerHTML = `<span class="item-active item-click">${boldActiveName}</span> <span class="item-brand item-click">${
        boldBrandName ? `(${boldBrandName})` : ''
      }</span>`;
      // Add dataset information here to update state when the user selects an item
      item.dataset.code = match['item-code'];
      item.dataset.activeIngredient = match['tpuu-or-mpp-pt'];
      // Brand name array will only ever contain one entry (each autocomplete option represents a single brand name)
      // Some drugs will not have an associated brand name (e.g. Atropine)
      item.dataset.brandName = match['brand-name'][0] ?? '';
      // FIXME: consider adding "compounded" to the finalised drug autocomplete data. As of writing,
      // this condition will never be reached
      if (match['compounded']) {
        item.dataset.compounded = 'true';
      }
      item.classList.add('item');
      item.classList.add('item-click');
      itemsList?.appendChild(item);
    }
  }, []);

  // Make absolutely sure any dependency functions in this hook are wrapped in useCallback
  useEffect(() => {
    const input = document.querySelector('#activeIngredient') as HTMLInputElement;

    // add item-click class to disable hiding of items list on outside click
    input.classList.add('item-click');

    // Check if the itemsList already has event listeners attached
    const currentItemsList = itemsListRef.current;
    if (currentItemsList) {
      currentItemsList.addEventListener('click', clickSuggestion);
      document.querySelector('.activeIngredient')?.appendChild(currentItemsList);
    }

    // Removes the active class from all autocomplete items
    const removeActive = (itemsArr: NodeListOf<HTMLElement>) => {
      itemsArr.forEach((item) => {
        item.classList.remove('active');
      });
    };

    // Add the active class to a specified item in the autocomplete list
    const addActive = (itemsArr: NodeListOf<HTMLElement>) => {
      // First remove any active classes
      removeActive(itemsArr);
      // If the user has pressed down more than the current length of the autocomplete list, or presses down on the last item, cycle back to the top of the list
      if (currentFocus.current >= itemsArr.length) {
        currentFocus.current = 0;
      }
      // Similarly, if the user presses up too many times, cycle to the bottom of the list
      if (currentFocus.current < 0) {
        currentFocus.current = itemsArr.length - 1;
      }
      itemsArr[currentFocus.current].classList.add('active');
    };

    // The currentFocus variable will be used as an index when adding an active class to an item in the itemsList list
    const keyItemNav = (e: KeyboardEvent) => {
      // This is the array of list items that will be moved through using the currentFocus variable
      const items = document.querySelectorAll('.item') as NodeListOf<HTMLElement>;
      if (items.length > 0) {
        if (e.key === 'ArrowDown') {
          currentFocus.current++;
          /*and and make the current item more visible:*/
          addActive(items);
        } else if (e.key === 'ArrowUp') {
          //up
          // Decrease the currentFocus variable when the DOWN key is pressed
          currentFocus.current--;
          /*and and make the current item more visible:*/
          addActive(items);
        } else if (e.key === 'Enter') {
          // Ensure the form isn't submitted when simply selecting an option
          e.preventDefault();
          if (currentFocus.current > -1) {
            // Simulated a click on the currently 'focused' item
            items[currentFocus.current].click();
          }
        }
      }
    };

    // Ensure the items list closes on outside click
    const itemsListOutsideClick = (e: MouseEvent) => {
      // All items within the autocomplete input and items list will contain this class as a marker of sorts
      const eventTarget = e.target as HTMLElement;
      if (!eventTarget.classList.contains('item-click')) {
        hideItemsList();
      }
    };

    // Check for non-whitespace character to indicate a valid value to create an autocomplete list from. Note this will only occur if the user performs tab out of the input, or an outside click
    const checkForListCreate = () => {
      if (input.value.trim().length > 0) {
        showItemsList();
      }
    };

    // Listen for tab out specifically, and hide the itemsList in response
    const tabOut = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        hideItemsList();
      }
    };

    input.addEventListener('focus', checkForListCreate);
    input.addEventListener('keydown', keyItemNav);
    input.addEventListener('keydown', tabOut);
    window.addEventListener('click', itemsListOutsideClick);

    return () => {
      // Remove event listeners on dismount
      input.removeEventListener('focus', checkForListCreate);
      input.removeEventListener('keydown', keyItemNav);
      input.removeEventListener('keydown', tabOut);
      window.removeEventListener('click', itemsListOutsideClick);
      currentItemsList?.removeEventListener('click', clickSuggestion);
    };
  }, [clickSuggestion, createList]);

  return (
    <StyledDrugAutocomplete
      className={`${expand ? 'DrugAutocomplete expanded' : 'DrugAutocomplete collapsed'}`}
    >
      <FormField
        id="activeIngredient"
        name="activeIngredient"
        label="Active ingredient"
        placeholder="Enter active ingredient or brand name"
        value={data.activeIngredient}
        onChange={(event) => {
          removeVerification();
          handleChange(event);
          handleSearch(event);
        }}
        alert={alerts.activeIngredient}
        className="activeIngredient form-field"
        required
      />
      <div ref={itemsListRef} className="items-list item-click">
        {/* items will be dynamically added here */}
      </div>
      <button
        type="button"
        className="drug-expand"
        onClick={() => setExpand((prevState) => !prevState)}
      >
        {expand ? 'Hide extra fields' : 'Enter manually'}
      </button>

      <div className={`drug-collapse ${expand ? 'show' : 'hide'}`}>
        <FormField
          id="brandName"
          name="brandName"
          label="Brand name"
          value={data.brandName}
          onChange={(event) => {
            removeVerification();
            handleChange(event);
          }}
          alert={alerts.brandName}
        />

        <FormField
          type="checkbox"
          name="includeBrand"
          label="Include brand name on prescription"
          onChange={() => toggle(setData, data, 'includeBrand')}
          checked={data.includeBrand}
          className="checkbox brand-checkbox"
          onKeyDown={(event) => changeOnEnter(event, setData, data)}
        />

        <div className="brandOnly-container">
          <FormField
            type="checkbox"
            name="brandOnly"
            label="Prescribe by brand name only"
            onChange={() => toggle(setData, data, 'brandOnly')}
            checked={data.brandOnly}
            className="checkbox"
            onKeyDown={(event) => changeOnEnter(event, setData, data)}
          />

          <Tooltip
            showTooltip={showTooltip}
            tooltipText={tooltipText}
            ariaLabel="Show more information on brand name vs active ingredient prescribing for this medication"
          />
        </div>

        <FormField
          type="checkbox"
          name="substitutePermitted"
          label="Brand substitution not permitted"
          onChange={() => toggle(setData, data, 'substitutePermitted')}
          checked={!data.substitutePermitted}
          className="checkbox"
          onKeyDown={(event) => changeOnEnter(event, setData, data)}
        />

        <FormField
          type="checkbox"
          name="compounded"
          label="To be compounded"
          onChange={() => toggle(setData, data, 'compounded')}
          checked={data.compounded}
          className="checkbox compounded"
          onKeyDown={(event) => changeOnEnter(event, setData, data)}
        />
      </div>
    </StyledDrugAutocomplete>
  );
};

export default DrugAutocomplete;
