import { z } from 'zod';

/**
 * Firestore database collection "scripts", a.k.a. prescriptions.
 *
 * NOTE: In a NoSQL context, any and all of these fields would be considered optional since
 * documents can contain any fields whatsoever. In practice we should try and adhere to some type
 * of schema.
 *
 * FIXME: Break up into constituent objects, e.g. drug data, PBS data, etc.
 */
// export type Prescription = {
//   activeIngredient: string;
//   age?: string;
//   authCode: string;
//   authRequired: boolean;
//   authRxNumber: string;
//   brandName: string;
//   brandOnly: boolean;
//   compounded: boolean;
//   date: string;
//   dateTime: string;
//   dosage: string;
//   favourite: boolean;
//   includeBrand: boolean;
//   indications: string;
//   itemCode: string;
//   justification?: string;
//   maxQuantity: string;
//   maxRepeats: string;
//   pbsRx: boolean;
//   prevAuth?: boolean;
//   quantity: string;
//   repeats: string;
//   scriptID: string;
//   substitutePermitted: boolean;
//   verified: boolean;
//   /**
//    * Specific to "favourite" prescriptions only. Consider abstracting to separate type
//    */
//   customName?: string;
// };

export type DrugData = {
  activeIngredient: string;
  brandName: string;
  quantity: string;
  repeats: string;
  dosage: string;
  itemCode: string;
  /**
   * Indicates if brand substitution is permitted
   */
  substitutePermitted: boolean;
  /**
   * Indicates whether the Rx should list brand name only (only permitted for certain drugs)
   */
  brandOnly: boolean;
  /**
   * Indicates whether brand name should be included on the Rx
   */
  includeBrand: boolean;
  /**
   * Indicates whether this is a PBS prescription
   */
  pbsRx: boolean;
  /**
   * Whether the prescription requires compounding
   */
  compounded: boolean;
  /**
   * Set to true when the user selects an autocomplete option. Set to false on any subsequent modification of drug information, as this cannot be verified on the PBS. Only those verified drugs should be integrated with PBS backend
   */
  verified: boolean;
  /**
   * Indications for the use of drug under PBS restriction
   */
  indications: string;
  authRequired: boolean;
  maxQuantity: string;
  maxRepeats: string;
};

// export type Address = {
//   /**
//    * The entire "street" component of the address, e.g. "6A Harrington Street"
//    */
//   streetAddress: string;
//   /**
//    * Apartment, unit, shop, suite, or floor. Rarely provided by Google places API.
//    */
//   subpremise: string;
//   suburb: string;
//   postcode: string;
//   state: string;
// };

export type PatientData = {
  fullName: string;
  medicareNumber: string;
  medicareRefNumber: string;
} & Address;

export type PrescriberData = {
  default: boolean;
  fullName: string;
  phoneNumber: string;
  practiceName: string;
  prefix: boolean;
  prescriberNumber: string;
  qualifications: string;
  /** User ID */
  uid: string;
} & Address;

export type MiscData = {
  /**
   * The date the Rx was written (can be manually modified)
   */
  date: string;
  /**
   * Auto-generated
   */
  authRxNumber: string;
  /**
   * Either a streamline authority code, or a code obtained via telephone or online approval
   */
  authCode: string;
  /**
   * Unique identifier for every prescription
   */
  scriptID: string;
  /**
   * The reason why the drug was prescribed (authority scripts only)
   */
  justification: string;
  /**
   * Whether the patient has received prior authority for this medication (authority scripts only)
   */
  prevAuth: false;
  /**
   * Age of the patient (authority scripts only)
   */
  age: string;
};

export const pbsDataSchema = z.object({
  'program-code': z.string(),
  'atc-level-code': z.string(),
  'atc-type': z.string(),
  'atc-print-option': z.string(),
  'item-code': z.string(),
  'restriction-flag': z.string(),
  'has-caution': z.string(),
  'has-note': z.string(),
  mq: z.string(),
  repeats: z.string(),
  'manufacturer-code': z.string(),
  'pack-size': z.string(),
  'markup-band': z.string(),
  'fee-code': z.string(),
  'dangerous-drug-code': z.string(),
  'brand-premium': z.string(),
  'therapeutic-premium': z.string(),
  cp2p: z.string(),
  cdpmq: z.string(),
  lp2p: z.string(),
  ldpmq: z.string(),
  mp2p: z.string(),
  mdpmq: z.string(),
  mrvsn: z.string(),
  bioequivalence: z.string(),
  'brand-name': z.array(z.string()),
  'mp-pt': z.string(),
  'tpuu-or-mpp-pt': z.string(),
  'indication-id': z.string(),
  'increase-code': z.string(),
  'note-ids': z.array(z.string()),
  caution_ids: z.array(z.string()),
  indications: z
    .object({
      description: z.string(),
      'misc-res-code': z.string(),
      'date-req': z.string(),
      'text-req': z.string(),
    })
    .partial(),
  notes: z.array(z.string()),
  cautions: z.array(z.string()),
  'streamline-code': z.string(),
  atc: z.string(),
  'caution-ids': z.array(z.string()),
  lmbc: z.boolean(),
  lemi: z.boolean(),
});

/**
 * JSON data shape for PBS medication entries directly downloaded from the PBS developers page.
 */
export type PbsData = z.infer<typeof pbsDataSchema>;

/**
 * A bare bones Rx data set used to initialise the Rx form
 */
export type InitialRxData = {
  drugData: {
    substitutePermitted: boolean;
    brandOnly: boolean;
    includeBrand: boolean;
    pbsRx: boolean;
    compounded: boolean;
    authRequired: boolean;
  };
  patientData: object;
  prescriberData: object;
  miscData: {
    date: string;
  };
  pbsData: null;
};

/**
 * Represents a 'complete' prescription data set separated into individual 'sections'
 */
export type RxData = {
  drugData: DrugData;
  patientData: PatientData;
  prescriberData: PrescriberData;
  miscData: MiscData;
  pbsData: PbsData | null;
};

/**
 * Represents an abridged version of the full PBS drug entry with only the information required
 * for use in an autocomplete option.
 */
export type DrugAutocompleteOption =
  | {
      'item-code': string;
      // FIXME: Consider making this a string rather than array of single string
      'brand-name': string[];
      'mp-pt': string;
      'tpuu-or-mpp-pt': string;
      // FIXME: Not actually yet included in any drug autocomplete options, need to include on PBS pipeline
      compounded?: boolean;
      lemi: boolean;
      lmbc: boolean;
    }
  | {
      'item-code': string;
      // FIXME: Consider making this a string rather than array of single string
      'brand-name': string[];
      'mp-pt': string;
      'tpuu-or-mpp-pt': string;
      // FIXME: Not actually yet included in any drug autocomplete options, need to include on PBS pipeline
      compounded?: boolean;
      lemi?: boolean;
      lmbc?: boolean;
    };

/**
 * A comprehensive list of all possible route pathnames in the app
 */
export type PathName =
  | '/'
  | '/privacy-policy'
  | '/terms-of-service'
  | '/features'
  | '/faq'
  | '/about'
  | '/dashboard'
  | '/settings'
  | '/prescription/new'
  | '/prescription/review'
  | '/signup'
  | '/login'
  | `/edit-prescriber/${string | number}`
  | '/add-prescriber'
  | '/prescribers'
  | '/scripts'
  | `/scripts/${string | number}`
  | '/reset-password';

export const numberDocumentSchema = z.object({
  current: z.string(),
});

/**
 * Represents the Firestore document type for `numbers` documents
 *
 * All currently defined documents in the `numbers` collection:
 * - `authRxNo`
 * - `scriptNo`
 */
export type NumberDocument = z.infer<typeof numberDocumentSchema>;

// SCHEMAS

export const addressSchema = z.object({
  streetAddress: z.string(),
  subpremise: z.string(),
  suburb: z.string(),
  postcode: z.string(),
  state: z.string(),
});

export const prescriberSchema = z
  .object({
    default: z.boolean(),
    fullName: z.string(),
    phoneNumber: z.string(),
    practiceName: z.string(),
    prefix: z.boolean(),
    prescriberNumber: z.string(),
    qualifications: z.string(),
    uid: z.string(),
  })
  .merge(addressSchema);

export const prescriptionSchema = z.object({
  activeIngredient: z.string(),
  age: z.string().optional(),
  authCode: z.string(),
  authRequired: z.boolean(),
  authRxNumber: z.string(),
  brandName: z.string(),
  brandOnly: z.boolean(),
  compounded: z.boolean(),
  date: z.string(),
  dateTime: z.string().optional(),
  dosage: z.string(),
  /**
   * Optional for scripts in a user's "favourites" and "scripts" lists,
   * but not for a prescription document
   */
  favourite: z.boolean().optional(),
  includeBrand: z.boolean(),
  indications: z.string(),
  itemCode: z.string(),
  justification: z.string().optional(),
  maxQuantity: z.string(),
  maxRepeats: z.string(),
  pbsRx: z.boolean(),
  prevAuth: z.boolean().optional(),
  quantity: z.string(),
  repeats: z.string(),
  scriptID: z.string(),
  substitutePermitted: z.boolean(),
  verified: z.boolean(),
  customName: z.string().optional(),
});

export const prescriberDocumentWithIdSchema = prescriberSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export const prescriptionDocumentSchema = prescriptionSchema.merge(
  z.object({
    /**
     * Getting a prescription document directly will guarantee the `favourite` property
     */
    favourite: z.boolean(),
  }),
);

export const prescriptionDocumentWithIdSchema = prescriptionSchema.merge(
  z.object({
    /**
     * Getting a prescription document directly will guarantee the `favourite` property
     */
    favourite: z.boolean(),
    id: z.string(),
  }),
);

export const userDocumentSchema = z.object({
  displayName: z.string(),
  favourites: z.array(
    prescriptionSchema.omit({
      favourite: true,
    }),
  ),
  scripts: z.array(
    prescriptionSchema.omit({
      favourite: true,
    }),
  ),
});

export type Address = z.infer<typeof addressSchema>;
export type Prescriber = z.infer<typeof prescriberSchema>;
export type Prescription = z.infer<typeof prescriptionSchema>;

/**
 * Raw Firestore prescriber document with ID appended
 */
export type PrescriberDocumentWithId = z.infer<typeof prescriberDocumentWithIdSchema>;

/**
 * Raw Firestore prescription document
 */
export type PrescriptionDocument = z.infer<typeof prescriptionDocumentSchema>;

/**
 * Raw Firestore prescription document with ID appended
 */
export type PrescriptionDocumentWithId = z.infer<typeof prescriptionDocumentWithIdSchema>;

/**
 * Raw Firestore user document
 */
export type UserDocument = z.infer<typeof userDocumentSchema>;
