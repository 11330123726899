import { StyledFavourites } from './Favourites.styled';
import Spinner from '../utils/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Link } from 'react-router-dom';
import Modal from '../utils/Modal/Modal';
import Button from '../utils/Button/Button';
import { useFormatting } from '../../hooks/useFormatting';
import type { User } from 'firebase/auth';
import { Prescription, userDocumentSchema } from '../../types/firestore';
import { useDocument } from '../../hooks/useDocument';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';
import toast from 'react-hot-toast';

type FavouritesProps = {
  user: User;
};

const Favourites = ({ user }: FavouritesProps) => {
  const { data, isLoading, error } = useDocument(`users/${user.uid}`, userDocumentSchema, {
    includeDocumentIds: false,
    // The user document is often not yet fully created on initial render(s)
    errorOnMissingDocument: false,
  });
  const { formatDrug } = useFormatting();

  const [showModal, setShowModal] = useState(false);

  // Initialised here to avoid reference errors in the modal when pulling selected script data
  const [selectedScript, setSelectedScript] = useState<Prescription | null>(null);

  // When delete modal is open, default to cancel button when user hits enter after pressing delete, aiming avoiding accidental deletes
  const cancelOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const cancelBtn: HTMLButtonElement | null = document.querySelector('.cancel-btn');

      if (cancelBtn) {
        event.preventDefault();
        cancelBtn.click();
      }
    }
  };

  // Add event listener for modal only once on initial render
  useEffect(() => {
    window.addEventListener('keydown', cancelOnEnter);

    return () => {
      window.removeEventListener('keydown', cancelOnEnter);
    };
  }, []);

  useHandleFetchError(error);

  // Remove favourite from firestore database
  const deleteFavourite = async (scriptToDelete: Prescription, allFavourites: Prescription[]) => {
    const docRef = doc(db, 'users', user.uid);
    const updatedFavourites = allFavourites.filter(
      (fav) => fav.scriptID !== scriptToDelete.scriptID,
    );

    try {
      await updateDoc(docRef, {
        favourites: updatedFavourites,
      });
      setShowModal(false);
      toast.success('Deleted script');
    } catch (err) {
      setShowModal(false);
      toast.error('An error occurred while deleting the favourite');
    }
  };

  if (isLoading) {
    <StyledFavourites>
      <Spinner />;
    </StyledFavourites>;
  }

  // Handle null data separately from an error state
  if (data === null) {
    return (
      <StyledFavourites>
        <h2 className="Favourites__title">Favourites</h2>
        <ul className="fav-list fav-list--none">
          <li className="fav-item fav-item--none">
            Favourites can be added by clicking on an individual script in the &apos;Scripts&apos;
            page
          </li>
        </ul>
      </StyledFavourites>
    );
  }

  if (error) {
    return (
      <StyledFavourites>
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">No favourites added yet</li>
        </ul>
      </StyledFavourites>
    );
  }

  return (
    <StyledFavourites>
      <h2 className="Favourites__title">Favourites</h2>
      {data.favourites.length > 0 ? (
        <>
          <ul className="fav-list">
            <li className="first-list-item">
              <span>Script name</span>
              <span className="actions-span">Actions</span>
            </li>
            {data.favourites.map((fav) => (
              <li key={fav.scriptID} className="fav-item">
                <div className="item-name">
                  <span className="cell-title">Script name</span>
                  <span className="item-content">
                    {fav.customName === '' ? formatDrug(fav) : fav.customName}
                  </span>
                </div>
                <div className="actions">
                  <span className="cell-title">Actions</span>
                  <div className="btns">
                    <Link
                      className="btn-primary prescribe"
                      to="/prescription/new"
                      state={{
                        newRx: true,
                        rePrescribe: true,
                        scriptData: fav,
                      }}
                    >
                      Prescribe
                    </Link>
                    <button
                      className="delete-btn"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedScript({
                          ...fav,
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <ul className="fav-list fav-list--none">
          <div className="list-header">Favourites</div>
          <li className="fav-item fav-item--none">
            Favourites can be added by clicking on an individual script in the &apos;Scripts&apos;
            page
          </li>
        </ul>
      )}

      {data && showModal && selectedScript && (
        <Modal
          title="Delete favourite"
          closeModal={() => setShowModal(false)}
          kind="delete"
          errorMessage="This will permanently delete the following favourite."
        >
          <div className="favourite-display">
            <div className="favourite-label">Selected script</div>
            <div className="favourite-summary">{`${
              selectedScript.customName === ''
                ? formatDrug(selectedScript)
                : selectedScript.customName
            }`}</div>
          </div>
          <div className="Modal__buttons">
            <Button classLabel="cancel" design="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              design="delete"
              onClick={() => deleteFavourite(selectedScript, data.favourites)}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </StyledFavourites>
  );
};

export default Favourites;
