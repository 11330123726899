import { ReactNode } from 'react';
import { styled } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LandingPageContainer = ({ children }: { children: ReactNode }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export { StyledContainer, LandingPageContainer };
