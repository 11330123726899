import { useState, useEffect, useRef } from 'react';
import { StyledRxForm } from './RxForm.styled';
import Fieldset from '../utils/Fieldset/Fieldset';
import { useLocation } from 'react-router';
import { useNumbers } from '../../hooks/useNumbers';
import { usePBSFetch } from '../../hooks/usePBSFetch';
import { Link } from 'react-router-dom';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import Button from '../utils/Button/Button';
import { useInputValidation } from '../../hooks/useInputValidation';
import PrescriberDetails from '../PrescriberDetails/PrescriberDetails';
import PatientDetails from '../PatientDetails/PatientDetails';
import { useHandleLEMI } from '../../hooks/useHandleLEMI';
import MedicationDetails from '../MedicationDetails/MedicationDetails';
import AuthorityDetails from '../AuthorityDetails/AuthorityDetails';
import { useNewRx } from '../../hooks/useNewRx';
import { useRxFormValidation } from '../../hooks/useRxFormValidation';
import { Helmet } from 'react-helmet-async';
import {
  DrugData,
  MiscData,
  PatientData,
  PbsData,
  Prescriber,
  PrescriberData,
  RxData,
} from '../../types/firestore';
import { AlertState, SetAlertFunc } from '../utils/Alert/Alert';
import type { User } from 'firebase/auth';

// Multiple items are not permitted to be prescribed on the same form; each must use an individual form (applies to optometrists only)
type RxFormProps = {
  googleLoaded: boolean;
  handleSubmit: (
    drugData: DrugData,
    patientData: PatientData,
    prescriberData: PrescriberData,
    miscData: MiscData,
    pbsData: PbsData | null,
  ) => void;
  existingData: RxData | null;
  user: User;
};

const RxForm = ({ handleSubmit, googleLoaded, existingData, user }: RxFormProps) => {
  // Location state is only provided if generating a new Rx. This signals certain functions to run (i.e. fetch numbers)
  const { state } = useLocation();
  const { scriptNo, authRxNo, numbersLoading, fetchNumbers } = useNumbers();
  const { pbsInfo, fetchDrug, setPbsInfo } = usePBSFetch(
    existingData ? existingData.pbsData : null,
  );
  const { negativeValidationUI, removeAllValidation } = useInputValidation();
  const { patientDataValidation, drugDataValidation, miscDataValidation } = useRxFormValidation();
  const { LEMIText, handleLEMIInfo } = useHandleLEMI();
  const { resetFormData, resetFormValidation } = useNewRx();

  const currentDate = new Date().toISOString().split('T')[0];
  // Avoid potentially re-attempting number fetching/transactions on component re-renders
  const hasFetchedNumbers = useRef(false);

  const [showTooltip, setShowTooltip] = useState(true);
  const [numbersLoaded, setNumbersLoaded] = useState(false);

  const [drugAlerts, setDrugAlerts] = useState<AlertState>({
    name: null,
    quantity: null,
    repeats: null,
    dosage: null,
    pbsRx: null,
    maxQuantity: null,
    maxRepeats: null,
    activeIngredient: null,
    authRequired: null,
  });

  const [patientAlerts, setPatientAlerts] = useState<AlertState>({
    fullName: null,
    streetAddress: null,
    suburb: null,
    postcode: null,
    state: null,
    medicareNumber: null,
    medicareRefNumber: null,
  });

  const [miscAlerts, setMiscAlerts] = useState<AlertState>({
    date: null,
    authRxNumber: null,
    authCode: null,
    scriptID: null,
    justification: null,
    prevAuth: null,
    age: null,
  });

  // These states have been separated for better logic and avoiding too much nesting. However they still draw on any existing submitted data. Merge them on form submit
  const [drugData, setDrugData] = useState<DrugData>({
    activeIngredient: '',
    brandName: '',
    quantity: '',
    repeats: '',
    dosage: '',
    itemCode: '',
    verified: false,
    indications: '',
    maxQuantity: '',
    maxRepeats: '',
    substitutePermitted: true,
    brandOnly: false,
    includeBrand: false,
    pbsRx: false,
    compounded: false,
    authRequired: false,
    ...(existingData?.drugData ?? {}),
  });

  // Note this will eventually be modified to match the address autocomplete data returned
  const [patientData, setPatientData] = useState<PatientData>({
    fullName: '',
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
    medicareNumber: '',
    medicareRefNumber: '',
    ...(existingData?.patientData ?? {}),
  });

  const [prescriberData, setPrescriberData] = useState<Prescriber>({
    prefix: false,
    fullName: '',
    qualifications: '',
    practiceName: '',
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
    phoneNumber: '',
    prescriberNumber: '',
    uid: user.uid,
    default: false,
    ...(existingData?.prescriberData ?? {}),
  });

  const [miscData, setMiscData] = useState<MiscData>({
    authRxNumber: '',
    authCode: '',
    scriptID: '',
    justification: '',
    prevAuth: false,
    age: '',
    date: currentDate,
    ...(existingData?.miscData ?? {}),
  });

  // --- FORM INITIALISATION FUNCTIONS ---

  // Generate the unique script and authRx numbers, and attach them to the local RxForm state. This is only performed when loading the RxForm component using 'Create new prescription' btn
  useEffect(() => {
    const expander: HTMLButtonElement | null = document.querySelector('.drug-expand');
    try {
      if (state.newRx && !hasFetchedNumbers.current) {
        // Use .then() to ensure the above scriptNo and authRxNo variables are set prior to attempting to set data state with them
        fetchNumbers().then(() => {
          setNumbersLoaded((prevData) => (prevData ? prevData : !prevData));
        });

        resetFormData(setDrugData, setPatientData, setMiscData);
        resetFormValidation(setDrugAlerts, setPatientAlerts);
        hasFetchedNumbers.current = true;
      }
      // If the user has clicked a prescribe or re-prescribe button to get here then newRx will still be true, but this additional logic must be run to initialise drug data
      if (state.rePrescribe) {
        // State will have scriptData attached. Set it to local state here at form initialisation
        setDrugData((prevData) => ({
          ...prevData,
          activeIngredient: state.scriptData.activeIngredient,
          brandName: state.scriptData.brandName,
          quantity: state.scriptData.quantity,
          repeats: state.scriptData.repeats,
          dosage: state.scriptData.dosage,
          itemCode: state.scriptData.itemCode,
          substitutePermitted: state.scriptData.substitutePermitted,
          brandOnly: state.scriptData.brandOnly,
          includeBrand: state.scriptData.includeBrand,
          pbsRx: state.scriptData.pbsRx,
          compounded: state.scriptData.compounded,
          verified: state.scriptData.verified,
        }));

        // Leave the verified status intact from the original script. Re-call the fetchDrug function; thereby updating all the authority, maxQuantity/repeats, PBS indications, and other related PBS/LEMI features

        // It is possible the drug data will be different from when the drug was initially prescribed. This will update when the fetch call is made, but some of the original script parameters (e.g. max quantity, repeats, or LEMI) may be inappropriate, and the user will not be aware. Consider a modal for scripts older than X months warning the user, or even manually add later if PBS undergoes major changes down the line
        fetchDrug(state.scriptData.itemCode);

        // Finally, expand the medication details section
        expander?.click();
      }
    } catch (error) {
      // If the Rx is not newly generated, a reference error will be thrown, where state is null. This occurs when the user clicks the make changes button. Expand the drug select in this case
      if (document.querySelector('.DrugAutocomplete')?.classList.contains('collapsed')) {
        expander?.click();
      }
    }
  }, [state, fetchNumbers, fetchDrug, resetFormValidation, resetFormData]);

  // Set local state with authRxNo and scriptNo fetched from firestore. Activates only when the numbers have been fetched, which is performed as part of generating a newRx
  useEffect(() => {
    if (numbersLoaded) {
      setMiscData((prevData) => ({
        ...prevData,
        scriptID: scriptNo,
        authRxNumber: authRxNo,
      }));
    }
  }, [numbersLoaded, authRxNo, scriptNo]);

  // --- FORM VALIDATION FUNCTIONS ---

  // Inline form validation
  useEffect(() => {
    patientDataValidation(setPatientAlerts, setPatientData);
    drugDataValidation(setDrugAlerts);
    miscDataValidation(setMiscAlerts);
  }, [drugDataValidation, patientDataValidation, miscDataValidation]);

  // Remove a visible error or alert from the brand name input when it changes from being required to not being required
  useEffect(() => {
    if (!drugData.includeBrand && !drugData.brandOnly) {
      removeAllValidation(document.querySelector('#brandName') as HTMLInputElement, setDrugAlerts);
    }
  }, [drugData.includeBrand, drugData.brandOnly, removeAllValidation]);

  // Returns boolean indicating if form is valid or not, and also highlights invalid fields on UI
  const checkFormValidation = () => {
    let valid = true;
    let inputFocused = false;

    const performInputValidation = (fieldName: string, setAlertFunc: SetAlertFunc) => {
      const input: HTMLInputElement | null = document.querySelector(`[name="${fieldName}"]`);
      if (!input) {
        console.error(`Could not find input with name ${fieldName}`);
        return;
      }
      if (input.value.trim().length === 0) {
        if (!inputFocused) {
          input.focus();
          inputFocused = true;
        }
        valid = false;
        negativeValidationUI(setAlertFunc, 'This field cannot be left blank', input);
      }
    };

    const requiredFields = {
      drug: ['activeIngredient', 'dosage', 'quantity', 'repeats'],
      patient: ['fullName', 'streetAddress', 'suburb', 'postcode', 'state'],
    };

    const medicareNumberInput: HTMLInputElement | null = document.querySelector('#medicareNumber');
    const medicareRefNumberInput: HTMLInputElement | null =
      document.querySelector('#medicareRefNumber');

    if (!medicareNumberInput || !medicareRefNumberInput) {
      console.error('Could not find medicare number input(s)');
      return;
    }

    requiredFields.patient.forEach((field) => {
      performInputValidation(field, setPatientAlerts);
    });

    // If the user has attempted to enter medicare information, we should validate it for correct input here, and by default check the IRN input
    if (medicareNumberInput.value.trim() !== '') {
      if (!/^[0-9]{10}$/.test(medicareNumberInput.value.trim())) {
        negativeValidationUI(
          setPatientAlerts,
          'Medicare number must be exactly 10 digits long',
          medicareNumberInput,
        );
        if (!inputFocused) {
          medicareNumberInput.focus();
          inputFocused = true;
        }
        valid = false;
      }
      if (!/^[1-9]{1}$/.test(medicareRefNumberInput.value.trim())) {
        negativeValidationUI(
          setPatientAlerts,
          'IRN must be a single digit between 1 through 9',
          medicareRefNumberInput,
        );
        if (!inputFocused) {
          medicareRefNumberInput.focus();
          inputFocused = true;
        }
        valid = false;
      }
    }

    requiredFields.drug.forEach((field) => {
      performInputValidation(field, setDrugAlerts);
    });

    // Brand name field should only be validated if the user has selected that brand name is required in some way
    if (drugData.brandOnly || drugData.includeBrand) {
      performInputValidation('brandName', setDrugAlerts);
    }

    // Only a single miscellaneous field is required to validate
    performInputValidation('date', setMiscAlerts);

    // Finally, check for any active error alerts that were not detected with the more basic submission validation
    if (document.querySelectorAll('.alert--error').length > 0) {
      valid = false;
    }
    return valid;
  };

  // --- PBS FUNCTIONS ---

  // Remove all relevant PBS and verified-dependent information when there is loss of verified status (i.e. user manually adjusts active ingredient or brand name field)
  useEffect(() => {
    const clearPbsInfo = () => {
      setDrugData((prevData) => ({
        ...prevData,
        authRequired: false,
        indications: '',
        maxQuantity: '',
        maxRepeats: '',
      }));

      setMiscData((prevData) => ({
        ...prevData,
        authCode: '',
      }));

      setMiscAlerts((prevAlerts) => ({
        ...prevAlerts,
        authCode: null,
      }));
    };

    // Toggle any PBS-related functionality if there is a change in verified status.
    if (!drugData.verified) {
      clearPbsInfo();
      setPbsInfo(null);
      setShowTooltip(false);
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        authRequired: {
          message: 'This prescription does not require authority',
          kind: 'neutral',
        },
        pbsRx: {
          message: 'Select a medication from the dropdown list for PBS information',
          kind: 'neutral',
        },
      }));
      setDrugAlerts((prevAlerts) => ({
        ...prevAlerts,
        maxQuantity: null,
        maxRepeats: null,
      }));

      // Only bother with an authority message to select a dropdown medication IF the user is trying to prescribe on PBS
      if (drugData.pbsRx) {
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'Select a medication from the dropdown list for authority information',
            kind: 'neutral',
          },
        }));
      }
    } else {
      if (!pbsInfo) {
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          pbsRx: {
            message: 'This item is not available on the PBS',
            kind: 'neutral',
          },
          authRequired: {
            message: 'This prescription does not require authority',
            kind: 'neutral',
          },
        }));
      }
    }
  }, [drugData.verified, drugData.pbsRx, setPbsInfo, pbsInfo]);

  // Function to call relevant data handlers when PBS information is successfully fetched. A long section of code. Organised as best as practicable, but fragile. Any changes will likely have unexpected effects.
  useEffect(() => {
    const handleMaxParametersInfo = (fetchedPBSData: PbsData) => {
      // PBS info-related effects here
      if (drugData.pbsRx) {
        // All PBS drugs have restrictions on quantity and repeats; set to local state
        setDrugData((prevData) => ({
          ...prevData,
          maxRepeats: fetchedPBSData['repeats'],
          maxQuantity: fetchedPBSData['mq'],
        }));

        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          maxQuantity: {
            message: `Maximum allowed quantity under the PBS is ${drugData.maxQuantity}`,
            kind: 'neutral',
          },
          maxRepeats: {
            message: `Maximum allowed repeats under the PBS is ${drugData.maxRepeats}`,
            kind: 'neutral',
          },
        }));
      } else {
        // If the above condition isn't met, it means the quantity and repeat values are gone, so no valid PBS drug exists. hence remove all alerts
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          maxQuantity: null,
          maxRepeats: null,
        }));
      }
    };

    const handleAuthorityInfo = (fetchedPBSData: PbsData) => {
      // All authority required items will have flag 'A'. Set auth status accordingly
      if (fetchedPBSData['restriction-flag'] === 'A' && drugData.pbsRx) {
        setDrugData((prevData) => ({
          ...prevData,
          authRequired: true,
        }));
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'This item requires an authority prescription',
            kind: 'neutral',
          },
          pbsRx: {
            message: 'This item is available on the PBS (authority required)',
            kind: 'neutral',
          },
        }));
        if (fetchedPBSData['streamline-code'].length > 0) {
          setMiscAlerts((prevAlerts) => ({
            ...prevAlerts,
            authCode: {
              message: 'This medication is available using the streamline code above',
              kind: 'success',
            },
          }));
          setMiscData((prevData) => ({
            ...prevData,
            authCode: fetchedPBSData['streamline-code'],
          }));
        } else {
          setMiscAlerts((prevAlerts) => ({
            ...prevAlerts,
            authCode: {
              message:
                'This medication requires an authority code, which can be obtained through PRODA',
              kind: 'neutral',
            },
          }));
        }
      } else {
        setDrugData((prevData) => ({
          ...prevData,
          authRequired: false,
        }));
        setDrugAlerts((prevAlerts) => ({
          ...prevAlerts,
          authRequired: {
            message: 'This prescription does not require authority',
            kind: 'neutral',
          },
        }));
      }
    };

    const handleRestrictionInfo = (fetchedPBSData: PbsData) => {
      // Check for restricted status
      switch (fetchedPBSData['restriction-flag']) {
        case 'R':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This is item is available on the PBS (restrictions apply)',
              kind: 'neutral',
            },
          }));
          // Add the indication to the local drugData state to allow certain conditional renders
          setDrugData((prevData) => ({
            ...prevData,
            indications: fetchedPBSData.indications.description ?? '',
          }));
          break;

        case 'U':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This is item is available on the PBS (unrestricted)',
              kind: 'neutral',
            },
          }));
          // Remove the indication to the local drugData state to allow certain conditional renders
          setDrugData((prevData) => ({
            ...prevData,
            indications: '',
          }));
          break;

        // All 'A' class items are also restricted with indications or Treatment criteria
        case 'A':
          setDrugAlerts((prevAlerts) => ({
            ...prevAlerts,
            pbsRx: {
              message: 'This item is available on the PBS (authority required)',
              kind: 'neutral',
            },
          }));
          setDrugData((prevData) => ({
            ...prevData,
            indications: fetchedPBSData.indications.description ?? '',
          }));
          break;

        default:
          break;
      }
    };

    if (pbsInfo) {
      handleLEMIInfo(pbsInfo, setDrugData);
      handleRestrictionInfo(pbsInfo);
      handleAuthorityInfo(pbsInfo);
      handleMaxParametersInfo(pbsInfo);
      setShowTooltip(true);
    }
  }, [
    pbsInfo,
    drugData.pbsRx,
    drugData.maxQuantity,
    drugData.maxRepeats,
    showTooltip,
    handleLEMIInfo,
  ]);

  return (
    <>
      <Helmet>
        <title>New prescription · OptomRx</title>
        <meta
          name="description"
          content="Write a new prescription, with a suite of tools to streamline the process."
        />
        <link rel="canonical" href="/prescription/new" />
      </Helmet>
      <ContentContainer>
        <PageHeader
          title="New prescription"
          description="Complete all sections required for your prescription"
        />
        <StyledRxForm
          className="rxform"
          onSubmit={(e) => {
            e.preventDefault();
            if (checkFormValidation()) {
              handleSubmit(drugData, patientData, prescriberData, miscData, pbsInfo);
            }
          }}
          autoComplete="off"
          noValidate
        >
          <div className="scriptNo" data-testid="scriptNo">
            Script number: {numbersLoading ? 'Loading...' : miscData.scriptID}
          </div>

          <Fieldset className="prescriber-form select-fieldset" legend="Prescriber details">
            <PrescriberDetails setData={setPrescriberData} user={user} />
          </Fieldset>

          <Fieldset className="patient-form" legend="Patient details">
            <PatientDetails
              data={patientData}
              setData={setPatientData}
              alerts={patientAlerts}
              setAlerts={setPatientAlerts}
              googleLoaded={googleLoaded}
            />
          </Fieldset>

          <Fieldset className="drug-form" legend="Medication details">
            <MedicationDetails
              data={drugData}
              setData={setDrugData}
              alerts={drugAlerts}
              setAlerts={setDrugAlerts}
              fetchDrug={fetchDrug}
              showTooltip={showTooltip}
              LEMIText={LEMIText}
            />
          </Fieldset>

          <Fieldset className="misc-form" legend="Authority details">
            <AuthorityDetails
              drugData={drugData}
              setDrugData={setDrugData}
              drugAlerts={drugAlerts}
              miscData={miscData}
              setMiscData={setMiscData}
              miscAlerts={miscAlerts}
              numbersLoading={numbersLoading}
            />
          </Fieldset>

          <div className="PrescriberForm__btns">
            <Button type="submit" classLabel="submit-btn">
              Generate prescription
            </Button>
            <Link to="/dashboard" className="cancel-btn btn-secondary button">
              Cancel
            </Link>
          </div>
        </StyledRxForm>
      </ContentContainer>
    </>
  );
};

export default RxForm;
