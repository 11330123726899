import { styled } from 'styled-components';

const StyledMain = styled.main<{ $isLoggedIn: boolean; $url: string }>`
  background-color: ${(props) =>
    props.$isLoggedIn || props.$url === '/login' || props.$url === '/signup'
      ? 'var(--background)'
      : '#fff'};
  width: 100%;
  padding: ${(props) => (props.$isLoggedIn ? '0 0.5rem' : '0')};
  flex-grow: 1;

  /* Critical for RxTemplate to render correctly */
  @media print {
    background-color: #fff;
    width: 100%;
    height: auto;
  }
`;

export { StyledMain };
