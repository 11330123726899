import { ReactNode } from 'react';
import { StyledContentContainer } from './ContentContainer.styled';

type ContentContainerProps = {
  children: ReactNode;
  earlyPadding?: boolean;
};

// Used to house main page content
const ContentContainer = ({ children, earlyPadding = false }: ContentContainerProps) => {
  return <StyledContentContainer $earlyPadding={earlyPadding}>{children}</StyledContentContainer>;
};

export default ContentContainer;
