// Define functions that handle input changes to various types of form inputs. These are frequently used around the app
export const useInputChanges = () => {
  // General text input change handler
  function handleChange<T>(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFunc: React.Dispatch<React.SetStateAction<T>>,
  ) {
    const { name, value } = event.target;
    setFunc((prevData: T) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Handle any input controlling boolean data, typically checkboxes
  function toggleBooleanState<T>(
    setFunc: React.Dispatch<React.SetStateAction<T>>,
    data: T,
    boolToChange: string,
  ) {
    let newState = true;
    // @ts-expect-error: Temporarily bypassing type checking as this will be entirely removed
    // or refactored at a later date
    if (data[boolToChange]) {
      newState = false;
    }
    setFunc((prevData) => ({
      ...prevData,
      [boolToChange]: newState,
    }));
  }

  // Ensure that pressing the enter key on checkboxes functions as expected without submitting any forms
  function handleEnterKeyOnCheckbox<T>(
    event: React.KeyboardEvent<HTMLInputElement>,
    setFunc: React.Dispatch<React.SetStateAction<T>>,
    data: T,
  ) {
    // If the enter key is pressed
    if (event.key === 'Enter') {
      const eventTarget = event.target as HTMLInputElement;
      event.preventDefault();
      toggleBooleanState(setFunc, data, eventTarget.name);
    }
  }

  return { handleChange, toggleBooleanState, handleEnterKeyOnCheckbox };
};
