import { StyledPageHeader } from './PageHeader.styled';

type PageHeaderProps = {
  title: string;
  description?: string;
};

const PageHeader = ({ title, description }: PageHeaderProps) => {
  return (
    <StyledPageHeader className="PageHeader" $description={description}>
      {title && <h2 className="title">{title}</h2>}
      {description && <p className="description">{description}</p>}
    </StyledPageHeader>
  );
};

export default PageHeader;
