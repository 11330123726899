import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';

let ErrorBoundary: BugsnagErrorBoundary | null = null;

if (import.meta.env.MODE === 'production') {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
  });

  const bugsnagReact = Bugsnag.getPlugin('react');

  ErrorBoundary = bugsnagReact ? bugsnagReact.createErrorBoundary(React) : null;
}

export default ErrorBoundary;
