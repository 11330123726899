import { styled } from 'styled-components';

const BannerContainer = styled.div`
  background-color: #cce5ff;
  color: #004085;
  padding: 1.25rem 1.5rem;
  margin: 2rem auto;
  max-width: 1140px;
  border: 1px solid #b8daff;
  border-radius: 0.25rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
`;

const CloseButton = styled.button`
  color: #004085;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
`;

const BannerHeading = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 0.25rem 0;
`;

const StyledList = styled.ul`
  padding-left: 1.25rem;
  margin: 0.5rem 0;
  list-style-type: square;
`;

const StyledListItem = styled.li`
  margin-bottom: 0.25rem;
  padding-left: 0.2rem;
`;

const StyledP = styled.p`
  padding: 0.25rem 0;
  font-weight: normal;
`;

const StyledA = styled.a`
  color: #004085;
  &:hover {
    text-decoration: none;
  }
`;

const Banner = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
  if (!isVisible) return null;

  return (
    <BannerContainer>
      <BannerHeading>OptomRx is getting some upgrades!</BannerHeading>
      <StyledP>We&apos;re making important updates that may affect you:</StyledP>
      <StyledList>
        <StyledListItem>
          The <strong>Prescribers</strong> tab will be renamed to <strong>Practices</strong>.
        </StyledListItem>
        <StyledListItem>
          Adding new practices will now pre-fill more information, making the process quicker and
          easier.
        </StyledListItem>
        <StyledListItem>
          Your individual prescriber details (e.g. Name, Prescriber number) will be found in{' '}
          <strong>Settings</strong>.
        </StyledListItem>
        <StyledListItem>
          Each clinician will now need their own account as accounts will be prescriber-specific.
        </StyledListItem>
      </StyledList>
      <StyledP>
        If you&apos;re sharing an account, please create your personal account by{' '}
        <strong>01/12/2024</strong>. After this, each account will be linked to a single clinician.
      </StyledP>
      <StyledP>
        Need help? Contact our support at{' '}
        <StyledA href="mailto:mail@optomrx.net">mail@optomrx.com.au</StyledA>
      </StyledP>
      <CloseButton type="button" aria-label="close alert" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#004085"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </CloseButton>
    </BannerContainer>
  );
};

export default Banner;
