// Reset the local form state and remove all UI alerts. Used when the user is creating a new Rx
import { useCallback } from 'react';
import { useInputValidation } from './useInputValidation';
import { DrugData, MiscData, PatientData } from '../types/firestore';
import { SetAlertFunc } from '../components/utils/Alert/Alert';

export const useNewRx = () => {
  const { removeAllValidation } = useInputValidation();

  const resetFormData = useCallback(
    (
      setDrugData: React.Dispatch<React.SetStateAction<DrugData>>,
      setPatientData: React.Dispatch<React.SetStateAction<PatientData>>,
      setMiscData: React.Dispatch<React.SetStateAction<MiscData>>,
    ) => {
      // Also reset all existing data
      setDrugData({
        activeIngredient: '',
        brandName: '',
        quantity: '',
        repeats: '',
        dosage: '',
        itemCode: '',
        substitutePermitted: true,
        brandOnly: false,
        includeBrand: false,
        pbsRx: false,
        compounded: false,
        verified: false,
        indications: '',
        authRequired: false,
        maxQuantity: '',
        maxRepeats: '',
      });

      setPatientData({
        fullName: '',
        streetAddress: '',
        subpremise: '',
        suburb: '',
        postcode: '',
        state: '',
        medicareNumber: '',
        medicareRefNumber: '',
      });

      setMiscData((prevData) => ({
        ...prevData,
        authRxNumber: '',
        authCode: '',
        scriptID: '',
        justification: '',
        prevAuth: false,
        age: '',
      }));
    },
    [],
  );

  const resetFormValidation = useCallback(
    (setDrugAlerts: SetAlertFunc, setPatientAlerts: SetAlertFunc) => {
      // Remove any and all validation and alerts
      document
        .querySelector('.patient-form')
        ?.querySelectorAll('input')
        .forEach((input) => {
          removeAllValidation(input, setPatientAlerts);
        });
      document
        .querySelector('.drug-form')
        ?.querySelectorAll('input')
        .forEach((input) => {
          if (input.name === 'pbsRx') {
            // Do nothing
          } else {
            removeAllValidation(input, setDrugAlerts);
          }
        });

      // Close any expanded address or medication sections
      if (document.querySelector('.AddressAutocomplete')?.classList.contains('expanded')) {
        const addressExpander = document.querySelector('.address-expand') as HTMLButtonElement;
        addressExpander.click();
      }

      if (document.querySelector('.DrugAutocomplete')?.classList.contains('expanded')) {
        const drugExpander = document.querySelector('.drug-expand') as HTMLButtonElement;
        drugExpander.click();
      }
    },
    [removeAllValidation],
  );

  return { resetFormData, resetFormValidation };
};
