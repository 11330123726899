import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../firebase/config';
import PrescriberForm from '../PrescriberForm/PrescriberForm';
import { StyledAddPrescriber } from './AddPrescriber.styled';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { PrescriberData } from '../../types/firestore';
import toast from 'react-hot-toast';

type AddPrescriberProps = {
  googleLoaded: boolean;
  user: User;
};

const AddPrescriber = ({ googleLoaded, user }: AddPrescriberProps) => {
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);
  const [prescriberData, setPrescriberData] = useState<PrescriberData>({
    prefix: false,
    fullName: '',
    qualifications: '',
    practiceName: '',
    streetAddress: '',
    subpremise: '',
    suburb: '',
    postcode: '',
    state: '',
    phoneNumber: '',
    prescriberNumber: '',
    default: false,
    uid: user.uid,
  });

  // Used when the user submits the form - saves a new prescriber referenced by their user ID
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsPending(true);

    try {
      // Update data on backend
      const newDoc = await addDoc(collection(db, 'prescribers'), {
        ...prescriberData,
        uid: user.uid,
      });
      console.log(newDoc);

      // Must reset state here before navigating back to the previous page (component will no longer be mounted otherwise)
      setIsPending(false);

      // Confirm save via toast message, and return to the previous page
      toast.success('New prescriber added');
      navigate('/prescribers');
    } catch (error) {
      setIsPending(false);
      // Throw error toast on screen, no further rendering is required, nor any specific error handling
      toast.error('An error occurred while adding prescriber');
    }
  };

  const cancelEdit = () => {
    navigate('/prescribers');
  };

  return (
    <>
      <Helmet>
        <title>Add prescriber · OptomRx</title>
        <meta
          name="description"
          content="Add a new prescriber profile for use with your prescriptions."
        />
        <link rel="canonical" href="/add-prescriber" />
      </Helmet>
      <ContentContainer>
        <StyledAddPrescriber>
          <PageHeader
            title="Add prescriber"
            description="Prescriber details will appear on your prescriptions"
          />
          <div className="form-container">
            <span className="form-title">Prescriber details</span>
            <PrescriberForm
              googleLoaded={googleLoaded}
              data={prescriberData}
              setData={setPrescriberData}
              handleSubmit={handleSubmit}
              handleCancel={cancelEdit}
              submitBtnLabel="Add prescriber"
              pending={isPending}
            />
          </div>
        </StyledAddPrescriber>
      </ContentContainer>
    </>
  );
};

export default AddPrescriber;
