import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import Button from '../utils/Button/Button';
import Modal from '../utils/Modal/Modal';
import { useFormatting } from '../../hooks/useFormatting';
import usePagination from '../../hooks/usePagination';
import { StyledPaginate } from '../Scripts/Pagination.styled';
import { PrescriberDocumentWithId } from '../../types/firestore';
import toast from 'react-hot-toast';

type PrescribersTableProps = {
  rowsPerPage: number;
  data: PrescriberDocumentWithId[];
};

const PrescribersTable = ({ data, rowsPerPage }: PrescribersTableProps) => {
  const { currentItems, pageCount, handlePageClick } = usePagination<PrescriberDocumentWithId>(
    data,
    rowsPerPage,
  );

  const { formatLocation } = useFormatting();

  const [showModal, setShowModal] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [selectedPrescriber, setSelectedPrescriber] = useState({
    fullName: '',
    location: '',
    id: '',
  });

  // Update both the UI checkboxes and backend to ensure only one prescriber can be set default at any one time
  const setAsDefault = async (currentPrescribers: PrescriberDocumentWithId[], provID: string) => {
    let prevDefault = null;
    setIsPending(true);

    try {
      // Remove the current default and record which prescriber this was
      for (let i = 0; i < currentPrescribers.length; i++) {
        // Identify the current default prescriber
        if (currentPrescribers[i].default) {
          prevDefault = currentPrescribers[i].id;
          // In any case, remove the current default user
          await updateDoc(doc(db, 'prescribers', currentPrescribers[i].id), {
            default: false,
          });
          break;
        }
      }

      for (let i = 0; i < currentPrescribers.length; i++) {
        // When reaching the prescriber that the user click on
        if (currentPrescribers[i].id === provID) {
          // Check that this is not the previous default
          if (provID !== prevDefault) {
            // And update defaults if so, ending the loop here
            await updateDoc(doc(db, 'prescribers', currentPrescribers[i].id), {
              default: true,
            });
            break;
          } else {
            // Do not reset any defaults and end the loop here
            break;
          }
        }
      }
      setIsPending(false);
      toast.success('Prescriber defaults updated');
    } catch (error) {
      setIsPending(false);
      toast.error('An error occurred while updating defaults');
    }
  };

  const deletePrescriber = async (provID: string) => {
    try {
      await deleteDoc(doc(db, 'prescribers', provID));
      setShowModal(false);
      toast.success('Prescriber has been removed');
    } catch (error) {
      setShowModal(false);
      toast.error('An error occurred while deleting prescribers defaults');
    }
  };

  // Default to cancel button when user hits enter after pressing delete, aiming avoiding accidental deletes
  const cancelOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const cancelBtn = document.querySelector('.cancel-btn') as HTMLButtonElement;

      if (cancelBtn) {
        event.preventDefault();
        cancelBtn.click();
      }
    }
  };

  // Add event listener only once on initial render
  useEffect(() => {
    window.addEventListener('keydown', cancelOnEnter);

    return () => {
      window.removeEventListener('keydown', cancelOnEnter);
    };
  }, []);

  return (
    <>
      <table className="table data-table" aria-describedby="Prescribers__description">
        <thead className="tableRowHeader">
          <tr role="row">
            <th role="columnheader" className="tableHeader" scope="col">
              Name
            </th>
            <th role="columnheader" className="tableHeader" scope="col">
              Location
            </th>
            <th role="columnheader" className="tableHeader actions-header" scope="col">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {currentItems.map((prescriber) => (
            <tr className="tableRowItems" key={prescriber.id}>
              <td data-title="Name" className="tableCell">
                {prescriber.fullName}
              </td>
              <td data-title="Location" className="tableCell">
                {formatLocation(
                  prescriber.practiceName,
                  prescriber.streetAddress,
                  prescriber.suburb,
                )}
              </td>

              <td data-title="Actions" className="tableCell actions-cell">
                <div className="btns">
                  <div className="non-default">
                    <Link
                      className="table__action edit"
                      to={`/edit-prescriber/${prescriber.id}`}
                      state={{ ...prescriber }}
                    >
                      Edit
                    </Link>
                    <button
                      className="table__action delete"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedPrescriber({
                          fullName: prescriber.fullName,
                          location: formatLocation(
                            prescriber.practiceName,
                            prescriber.streetAddress,
                            prescriber.suburb,
                          ),
                          id: prescriber.id,
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>

                  <button
                    className={`${
                      prescriber.default && !isPending
                        ? 'table__action default--selected'
                        : 'table__action default'
                    }`}
                    onClick={() => setAsDefault(data, prescriber.id)}
                  >
                    {isPending
                      ? 'Updating...'
                      : `${prescriber.default ? 'Remove default' : 'Set as default'}`}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <StyledPaginate
          breakLabel="..."
          nextLabel="&raquo;"
          previousLabel="&laquo;"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
        />
      </div>

      {showModal && (
        <Modal
          title="Delete prescriber"
          closeModal={() => setShowModal(false)}
          kind="delete"
          errorMessage="This will permanently delete the following prescriber details"
        >
          <div className="prescriber-display">
            <div className="prescriber-label">Selected prescriber</div>
            <div className="prescriber-summary">{`${selectedPrescriber.fullName} (${selectedPrescriber.location})`}</div>
          </div>
          <div className="Modal__buttons">
            <Button classLabel="cancel" design="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button design="delete" onClick={() => deletePrescriber(selectedPrescriber.id)}>
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PrescribersTable;
