import { styled } from 'styled-components';

const StyledDashboard = styled.div`
  .Dashboard__link {
    max-width: 170px;
    margin-top: 1.85rem;
  }
`;

export { StyledDashboard };
