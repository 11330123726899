import Spinner from '../utils/Spinner/Spinner';
import Select from 'react-select';
import type { SingleValue, StylesConfig } from 'react-select';
import { Link } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import { useCallback, useEffect, useState } from 'react';
import { PrescriberData, prescriberDocumentWithIdSchema } from '../../types/firestore';
import type { User } from 'firebase/auth';
import { where } from 'firebase/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';

type PrescriberDetailsProps = {
  setData: React.Dispatch<React.SetStateAction<PrescriberData>>;
  user: User;
};

type SelectOption = {
  name: string;
  value: string;
  isDefault?: boolean;
};

type IsMulti = false;

// Created to improve parent RxForm readability only
const PrescriberDetails = ({ setData, user }: PrescriberDetailsProps) => {
  const getQueryConstraints = useCallback(() => [where('uid', '==', user.uid)], [user.uid]);
  const {
    data: prescribers,
    isLoading,
    error,
  } = useCollection('prescribers', prescriberDocumentWithIdSchema, {
    includeDocumentIds: true,
    getQueryConstraints,
  });
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [chosenPrescriber, setChosenPrescriber] = useState<SelectOption | null>(null);
  useHandleFetchError(error);

  // Used to fill the React Select component options using prescribers fetched from firestore. Will also set the selected option to the default prescriber if one exists
  useEffect(() => {
    // Do not run unless a prescribers collection exists (i.e. has been fetched from firebase)
    if (prescribers) {
      const prescriberSelectOptions: SelectOption[] = [];

      prescribers.forEach((prescriber, index) => {
        const option: SelectOption = {
          value: prescriber.id,
          name: `${prescriber.fullName} (${
            prescriber.practiceName !== ''
              ? prescriber.practiceName + `, ${prescriber.suburb}`
              : prescriber.suburb
          })`,
        };

        // Add the prescriber to the select option list
        prescriberSelectOptions.push(option);

        // Set the first prescriber as selected. If a default exists, this will be replaced
        if (index === 0 || prescriber.default) {
          setChosenPrescriber(option);
          // Also set state to prescriber data to ensure the form is pre-filled. Do NOT use previous data. Overwrite.
          setData({
            ...prescriber,
          });
        }
      });
      setSelectOptions(prescriberSelectOptions);
    }
  }, [prescribers, setData]);

  // A handle change function specifically for the select element. Sets both the input state and prescriberData based on selection
  const handleSelectChange = (newValue: SingleValue<SelectOption>) => {
    setChosenPrescriber(newValue);
    // Use the prescriber document ID to grab the prescriber from the fetched prescribers array
    const prescriberId = newValue?.value;
    // Note the prescriber is returned from array.filter as an array, hence destructuring
    if (!prescribers) {
      return;
    }
    const [prescriber] = prescribers.filter((prescriber) => prescriber.id === prescriberId);
    setData({
      ...prescriber,
    });
  };

  // Sets the CSS styles for React Select component
  const customStyles: StylesConfig<SelectOption, IsMulti> = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? '1px solid rgb(144, 147, 150)' : '1px solid rgb(144, 147, 150)',
      boxShadow: state.isFocused ? '0' : '0',
      outline: state.isFocused ? '2px solid #104362' : 'none',
      outlineOffset: state.isFocused ? '2px' : 'none',
      width: '26rem',
      padding: '0.12rem 0 0.11rem 0.85rem',
      borderRadius: '4px',
      fontSize: '1rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',

      '&:hover': {
        borderColor: state.isFocused ? '#104362' : 'rgb(178, 182, 185)',
        cursor: 'pointer',
      },

      '@media (max-width: 590px)': {
        width: '100%',
        maxWidth: '26rem',
        marginRight: '1.5rem',
      },
    }),

    menu: (base) => ({
      ...base,
      maxWidth: '26rem',
    }),

    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };

  return (
    <>
      {isLoading && <Spinner />}
      {prescribers && (
        <>
          {prescribers.length > 0 ? (
            <>
              <label id="react-select-id" htmlFor="react-select">
                Select prescriber
                <Select
                  options={selectOptions}
                  value={chosenPrescriber}
                  onChange={(newValue) => handleSelectChange(newValue)}
                  styles={customStyles}
                  placeholder="Select prescriber..."
                  id="react-select"
                  aria-labelledby="react-select-id"
                  getOptionLabel={(option: SelectOption) => option.name}
                  getOptionValue={(option: SelectOption) => option.value}
                  isMulti={false}
                />
              </label>
            </>
          ) : (
            <Link className="btn-primary add-new-btn" to="/add-prescriber">
              Add new prescriber
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default PrescriberDetails;
