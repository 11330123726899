import { StyledPrescribers } from './Prescribers.styled';
import { Link } from 'react-router-dom';
import PrescribersTable from './PrescribersTable';
import Spinner from '../utils/Spinner/Spinner';
import { useCallback } from 'react';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { useCollection } from '../../hooks/useCollection';
import { where } from 'firebase/firestore';
import { prescriberDocumentWithIdSchema } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';

type PrescribersProps = {
  user: User;
};

const Prescribers = ({ user }: PrescribersProps) => {
  const getQueryConstraints = useCallback(() => [where('uid', '==', user.uid)], [user.uid]);
  const {
    data: prescribers,
    isLoading,
    error,
  } = useCollection('prescribers', prescriberDocumentWithIdSchema, {
    includeDocumentIds: true,
    getQueryConstraints,
  });

  useHandleFetchError(error);

  return (
    <>
      <Helmet>
        <title>Prescribers · OptomRx</title>
        <meta
          name="description"
          content="Add, edit, or delete prescriber profiles. Use these profiles as needed when you create new scripts."
        />
        <link rel="canonical" href="/prescribers" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledPrescribers className="Prescribers">
          <PageHeader
            title="Prescribers"
            description="Add and modify prescriber details for your prescriptions"
          />

          <Link className="Prescribers__add-btn btn-primary" to={`/add-prescriber`}>
            Add new prescriber
          </Link>

          <div className="Prescribers__container">
            {isLoading && <Spinner />}

            {prescribers && (
              <>
                {prescribers.length > 0 ? (
                  <PrescribersTable data={prescribers} rowsPerPage={10} />
                ) : (
                  <table className="table table-none">
                    <thead className="tableRowHeader">
                      <tr>
                        <th className="tableHeader">Name</th>
                        <th className="tableHeader">Location</th>
                        <th className="tableHeader actions-header">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tableRowItems">
                        <td data-title="Prescribers" className="tableCellNone" colSpan={3}>
                          No prescribers added yet
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </StyledPrescribers>
      </ContentContainer>
    </>
  );
};

export default Prescribers;
