import { StyledScripts } from './Scripts.styled';
import ScriptsTable from './ScriptsTable';
import Spinner from '../utils/Spinner/Spinner';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';
import type { User } from 'firebase/auth';
import { useDocument } from '../../hooks/useDocument';
import { userDocumentSchema } from '../../types/firestore';
import { useHandleFetchError } from '../../hooks/useHandleFetchError';

type ScriptsProps = {
  user: User;
};

const Scripts = ({ user }: ScriptsProps) => {
  const { data, isLoading, error } = useDocument(`users/${user.uid}`, userDocumentSchema);
  useHandleFetchError(error);

  return (
    <>
      <Helmet>
        <title>Scripts · OptomRx</title>
        <meta
          name="description"
          content="View all the prescriptions you have written. Saved scripts contain all medication details for that script, but no patient data."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledScripts className="Scripts">
          <PageHeader
            title="Scripts"
            description="Review previous prescriptions you have written"
          />

          <div className="Scripts__container">
            {isLoading && <Spinner />}

            {/* Present an empty table if scripts can't be fetched, or none exist (below) */}
            {error ? (
              <table className="table table-none">
                <thead className="tableRowHeader">
                  <tr>
                    <th className="tableHeader">Script ID</th>
                    <th className="tableHeader">Medication</th>
                    <th className="tableHeader">Date prescribed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tableRowItems">
                    <td className="tableCellNone" colSpan={3}>
                      No scripts written yet
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}

            {data && (
              <>
                {data.scripts.length > 0 ? (
                  <>
                    <ScriptsTable scripts={[...data.scripts].reverse()} scriptsPerPage={15} />
                  </>
                ) : (
                  <table className="table table-none">
                    <thead className="tableRowHeader">
                      <tr>
                        <th className="tableHeader">Script ID</th>
                        <th className="tableHeader">Medication</th>
                        <th className="tableHeader">Date prescribed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tableRowItems">
                        <td className="tableCellNone" colSpan={3}>
                          No scripts written yet
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </StyledScripts>
      </ContentContainer>
    </>
  );
};

export default Scripts;
