import { StyledDashboard } from './Dashboard.styled';
import { Link } from 'react-router-dom';
import Favourites from '../Favourites/Favourites';
import { useEffect, useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import type { User } from 'firebase/auth';
import Modal from '../utils/Modal/Modal';
import ContentContainer from '../utils/ContentContainer/ContentContainer';
import PageHeader from '../utils/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';

type DashboardProps = {
  user: User;
  setFirstSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  firstSignIn: boolean;
};

const Dashboard = ({ user, firstSignIn, setFirstSignIn }: DashboardProps) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // If the user has just signed up for an account and hits the home page for the first time, display the email modal
    if (firstSignIn) {
      setShowModal(true);
      setFirstSignIn(false);
      // Not relevant to display error or success to user, they will be able to manually resend if no email is received. The manual resend has success/error UI handlers. Hence no .then or .catch is included
      sendEmailVerification(user);
    }
  }, [firstSignIn, setFirstSignIn, user]);

  return (
    <>
      <Helmet>
        <title>Dashboard · OptomRx</title>
        <meta
          name="description"
          content="Choose a favourite script to quickly write a prescription, or start fresh with a blank prescription."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <ContentContainer earlyPadding={true}>
        <StyledDashboard className="Dashboard">
          <PageHeader
            title={`Welcome, ${user.displayName}`}
            description="Create a new script or prescribe one of your favourites"
          />

          <Link
            className="Dashboard__link btn-primary"
            to="/prescription/new"
            state={{ newRx: true }}
          >
            New prescription
          </Link>

          <div className="Favourites">
            <Favourites user={user} />
          </div>

          {showModal && (
            <Modal
              title="Verify your email"
              closeModal={() => setShowModal(false)}
              kind="emailVerify"
            />
          )}
        </StyledDashboard>
      </ContentContainer>
    </>
  );
};

export default Dashboard;
