import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './components/ErrorBoundary/BugsnagErrorBoundary';
import { ErrorView } from './components/ErrorBoundary/ErrorView';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const InnerTree = (
  <>
    <HelmetProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  </>
);
root.render(
  <React.StrictMode>
    {/* TODO: Use custom error boundary to wrap (without logging) should BugSnag fail */}
    {ErrorBoundary ? (
      <ErrorBoundary FallbackComponent={ErrorView}>{InnerTree}</ErrorBoundary>
    ) : (
      InnerTree
    )}
  </React.StrictMode>,
);
