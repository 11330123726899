import { Link, useLocation } from 'react-router-dom';
import { StyledNav } from './Nav.styled';
import { useLogout } from '../../hooks/useLogout';
import { useState } from 'react';
import Hamburger from '../utils/Hamburger/Hamburger';
import { useAuthContext } from '../../hooks/useAuthContext';
import { PathName } from '../../types/firestore';
import toast from 'react-hot-toast';

const Nav = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const [showNav, setShowNav] = useState(false);
  const [toggleHamburger, setToggleHamburger] = useState(false);

  const location = useLocation();

  // Aria current page attributes that adjust depending on the current active page state
  const getAriaCurrentPage = (path: PathName): { 'aria-current'?: 'page' } => {
    return location.pathname === path ? { 'aria-current': 'page' } : {};
  };

  // Shows or hides the Nav menu on mobile
  const toggleNav = () => {
    setShowNav((prevState) => !prevState);
    setToggleHamburger((prevState) => !prevState);
  };

  // Specifically used to close the menu, rather than toggle
  const closeNav = () => {
    setShowNav(false);
    setToggleHamburger(false);
  };

  // Combine the logout function with confirmation to the user of successful logout
  const performLogout = () => {
    closeNav();
    try {
      logout();
      toast.success('Successfully logged out.');
    } catch (error) {
      toast.error('An error occurred while logging out. Try again.');
    }
  };

  return (
    <StyledNav $user={user} className="Nav" aria-label="Main menu" role="navigation">
      <ul className={`${showNav ? 'Nav__list active' : 'Nav__list'}`} role="menubar">
        {!user && (
          <>
            <li className="Nav__list-item" role="menuitem">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std Nav__link--homepage"
                to="/features"
                {...getAriaCurrentPage('/features')}
              >
                Features
              </Link>
            </li>
            <li className="Nav__list-item" role="menuitem">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std Nav__link--homepage"
                to="/faq"
                {...getAriaCurrentPage('/faq')}
              >
                FAQ
              </Link>
            </li>
            <li className="Nav__list-item" role="menuitem">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std Nav__link--homepage"
                to="/login"
                {...getAriaCurrentPage('/login')}
              >
                Log in
              </Link>
            </li>
            <li className="Nav__list-item" role="menuitem">
              {/* To style separately to log in btn, add class 'Nav__link--signup */}
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std Nav__link--homepage"
                to="/signup"
                {...getAriaCurrentPage('/signup')}
              >
                Sign up
              </Link>
            </li>
          </>
        )}

        {user && (
          <>
            <li className="Nav__list-item" role="none">
              <Link
                className="Nav__link Nav__link--std"
                to="/prescription/new"
                role="menuitem"
                onClick={closeNav}
                {...getAriaCurrentPage('/prescription/new')}
                state={{ newRx: true }}
              >
                New Rx
              </Link>
            </li>
            <li className="Nav__list-item" role="none">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std"
                to="/scripts"
                role="menuitem"
                {...getAriaCurrentPage('/scripts')}
              >
                Scripts
              </Link>
            </li>
            <li className="Nav__list-item" role="none">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std"
                to="/prescribers"
                role="menuitem"
                {...getAriaCurrentPage('/prescribers')}
              >
                Prescribers
              </Link>
            </li>
            <li className="Nav__list-item" role="none">
              <Link
                onClick={closeNav}
                className="Nav__link Nav__link--std"
                to="/settings"
                role="menuitem"
                {...getAriaCurrentPage('/settings')}
              >
                Settings
              </Link>
            </li>

            <li className="Nav__list-item" role="none">
              <button className="Nav__link Nav__link--std" onClick={performLogout} role="menuitem">
                Log out
              </button>
            </li>
          </>
        )}
      </ul>

      <Hamburger
        loggedIn={user ? true : false}
        handleClick={toggleNav}
        expanded={toggleHamburger}
      />
    </StyledNav>
  );
};

export default Nav;
