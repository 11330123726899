import { ReactNode } from 'react';
import { StyledMain } from './Main.styled';
import { useLocation } from 'react-router-dom';

type MainProps = {
  children: ReactNode;
  isLoggedIn: boolean;
};

const Main = ({ children, isLoggedIn }: MainProps) => {
  const { pathname } = useLocation();
  return (
    <StyledMain className="Main" role="main" $isLoggedIn={isLoggedIn} $url={pathname}>
      {children}
    </StyledMain>
  );
};

export default Main;
