import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import cat404 from '../../assets/404.jpg';

const StyledNotFound = styled.div`
  text-align: center;
  margin-top: 2rem;

  img {
    max-width: 600px;
    height: auto;
    padding-bottom: 1rem;
  }
`;

const NotFound = () => {
  return (
    <StyledNotFound>
      <img src={cat404} alt="404: Page not found." />
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>
        You can always go back to the <Link to="/">homepage</Link>.
      </p>
    </StyledNotFound>
  );
};

export default NotFound;
