import { useCallback, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

export const useGoogleAPI = () => {
  const [googleLoaded, setGoogleLoaded] = useState(false);

  const loadGoogleAPI = useCallback(() => {
    if (typeof google !== 'undefined') {
      setGoogleLoaded(true);
      return;
    }

    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader
      .load()
      .then(() => {
        setGoogleLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading Google Maps', error);
      });
  }, []);

  return { googleLoaded, loadGoogleAPI };
};
