import Bugsnag from '@bugsnag/js';
import toast from 'react-hot-toast';

/**
 * Notifies Bugsnag of an error, and displays an error toast to the user.
 */
export function handleError(error: unknown, message?: string) {
  if (error instanceof Error) {
    Bugsnag.notify(error);
    toast.error(message ?? error.message);
  } else {
    // Report more context about the non-Error
    const errorDetails = typeof error === 'object' ? JSON.stringify(error) : String(error);
    Bugsnag.notify(`Unknown non-Error instance triggered during fetch: ${errorDetails}`);
    toast.error('An unknown error occurred. Please try again.');
  }
}
