import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import RxForm from '../RxForm/RxForm';
import RxTemplate from '../RxTemplate/RxTemplate';
import type { User } from 'firebase/auth';
import { useState } from 'react';
import {
  DrugData,
  MiscData,
  PatientData,
  PbsData,
  PrescriberData,
  RxData,
} from '../../types/firestore';
import NotFound from '../NotFound/NotFound';

type PrescriptionRoutesProps = {
  user: User | null;
  googleLoaded: boolean;
};

export const PrescriptionRoutes = ({ user, googleLoaded }: PrescriptionRoutesProps) => {
  const [data, setData] = useState<RxData | null>(null);
  const navigate = useNavigate();

  // Primarily a UI-based function. Data will always be reset for new Rx within the Rx form, but
  // resetting data here on click of any link generating a new Rx avoid the flash of text on
  // initial render containing the old data. Bad look.
  const resetData = () => {
    setData(null);
  };

  // Combine all the data from the RxForm component
  const handleSubmit = (
    drugData: DrugData,
    patientData: PatientData,
    prescriberData: PrescriberData,
    miscData: MiscData,
    pbsData: PbsData | null,
  ) => {
    setData((prevData) => ({
      ...prevData,
      drugData: {
        ...drugData,
      },
      patientData: {
        ...patientData,
      },
      prescriberData: {
        ...prescriberData,
      },
      miscData: {
        ...miscData,
      },
      pbsData: pbsData,
    }));

    // New React Router v6 syntax using navigate. State is passed in a similar way and accessed
    // with useLocation
    navigate('/prescription/review');
  };

  if (!user) return <Navigate to="/login" />;

  return (
    <Routes>
      <Route
        path="new"
        element={
          <RxForm
            handleSubmit={handleSubmit}
            googleLoaded={googleLoaded}
            existingData={data}
            user={user}
          />
        }
      />
      <Route path="review" element={<RxTemplate data={data} resetData={resetData} user={user} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
