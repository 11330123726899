// Setup inline validation for the Rx Form component. Used only as a means of helping readability of the Rx Form component itself.
import { useInputValidation } from './useInputValidation';
import { useFormatting } from './useFormatting';
import { useCallback } from 'react';
import { SetAlertFunc } from '../components/utils/Alert/Alert';
import { PatientData } from '../types/firestore';

export const useRxFormValidation = () => {
  const { validateRequiredField, positiveValidationUI, negativeValidationUI } =
    useInputValidation();
  const { abbreviateStateName } = useFormatting();
  // Validation functions are split via form to utilise event propagation within the form
  const patientDataValidation = useCallback(
    (setAlertFunc: SetAlertFunc, setFunc: React.Dispatch<React.SetStateAction<PatientData>>) => {
      document.querySelector('.patient-form')?.addEventListener('focusout', (event) => {
        const eventTarget = event.target as HTMLInputElement;
        const { name, value } = eventTarget;
        switch (true) {
          case name === 'fullName':
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          case name === 'streetAddress':
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          case name === 'suburb':
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          case name === 'state':
            setFunc((prevData) => ({
              ...prevData,
              [name]: abbreviateStateName(value),
            }));
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          case name === 'medicareNumber':
            if (/^[0-9]{10}$/.test(value.trim())) {
              positiveValidationUI(setAlertFunc, eventTarget);
            } else {
              // ignore
              eventTarget.classList.remove('success');
              // Remove the tick icon
              const tick = eventTarget.parentNode?.querySelector('.tickCircle');
              tick?.classList.remove('show');
              tick?.classList.add('hide');
            }
            break;

          case name === 'medicareRefNumber':
            if (/^[1-9]{1}$/.test(value.trim())) {
              positiveValidationUI(setAlertFunc, eventTarget);
            } else {
              // ignore
              eventTarget.classList.remove('success');
              // Remove the tick icon
              const tick = eventTarget.parentNode?.querySelector('.tickCircle');
              tick?.classList.remove('show');
              tick?.classList.add('hide');
            }
            break;

          case name === 'postcode':
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          default:
            break;
        }
      });
    },
    [abbreviateStateName, positiveValidationUI, validateRequiredField],
  );

  const drugDataValidation = useCallback(
    (setAlertFunc: SetAlertFunc) => {
      document.querySelector('.drug-form')?.addEventListener('focusout', (event) => {
        const eventTarget = event.target as HTMLInputElement;
        const { name, value } = eventTarget;
        switch (true) {
          case name === 'activeIngredient':
            validateRequiredField(setAlertFunc, eventTarget);
            break;

          case name === 'brandName':
            if (value.trim().length > 0) {
              eventTarget.classList.remove('error');
              setAlertFunc((prevAlerts) => ({
                ...prevAlerts,
                brandName: null,
              }));
            }
            break;

          case name === 'quantity':
            // Verify as standard
            if (value.trim().length === 0) {
              negativeValidationUI(setAlertFunc, 'This field cannot be left blank', eventTarget);
            } else if (!/^[1-9][0-9]*$/.test(value.trim())) {
              // Checks for non-zero number with no theoretical limit
              negativeValidationUI(
                setAlertFunc,
                'Please enter a quantity of 1 or more (with no leading zeroes)',
                eventTarget,
              );
            } else {
              positiveValidationUI(setAlertFunc, eventTarget);
            }
            break;

          // Can be zero, and for non-PBS prescriptions, there is technically no upper limits
          case name === 'repeats':
            // Verify as standard
            if (value.trim().length === 0) {
              negativeValidationUI(setAlertFunc, 'This field cannot be left blank', eventTarget);
            } else if (!/^([1-9][0-9]*)|(0)$/.test(value.trim())) {
              // Checks for non-zero number with no theoretical limit
              negativeValidationUI(
                setAlertFunc,
                'Please enter a valid number (no leading zeroes)',
                eventTarget,
              );
            } else {
              positiveValidationUI(setAlertFunc, eventTarget);
            }
            break;

          case name === 'dosage':
            validateRequiredField(setAlertFunc, eventTarget);
            break;
          default:
            break;
        }
      });
    },
    [negativeValidationUI, positiveValidationUI, validateRequiredField],
  );

  // Although only a single field is being validated, the switch statement should remain in case more fields need to be added
  const miscDataValidation = useCallback(
    (setAlertFunc: SetAlertFunc) => {
      document.querySelector('.misc-form')?.addEventListener('focusout', (event) => {
        const { name, value } = event.target as HTMLInputElement;
        switch (true) {
          case name === 'date':
            if (value.trim() === '') {
              negativeValidationUI(
                setAlertFunc,
                'Please enter the current date',
                event.target as HTMLInputElement,
              );
            }
            break;
          default:
            break;
        }
      });
    },
    [negativeValidationUI],
  );
  return { patientDataValidation, drugDataValidation, miscDataValidation };
};
