import { ReactNode } from 'react';
import { StyledButton } from './Button.styled';

export type ButtonDesign = 'primary' | 'secondary' | 'ghost' | 'delete';

type ButtonProps = {
  children: ReactNode;
  design?: ButtonDesign;
  classLabel?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, design = 'primary', classLabel, ...props }: ButtonProps) => {
  return (
    <StyledButton className={`button--${design} button ${classLabel ? classLabel : ''}`} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
