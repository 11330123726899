import Header from './components/Header/Header';
import GlobalStyles from './components/utils/globalStyles';
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import Signup from './components/Signup/Signup';
import Login from './components/Login/Login';
import { useAuthContext } from './hooks/useAuthContext';
import Dashboard from './components/Dashboard/Dashboard';
import Prescribers from './components/Prescribers/Prescribers';
import './App.css';
import EditPrescriber from './components/EditPrescriber/EditPrescriber';
import AddPrescriber from './components/AddPrescriber/AddPrescriber';
import Main from './components/Main/Main';
import Scripts from './components/Scripts/Scripts';
import ViewScript from './components/ViewScript/ViewScript';
import Settings from './components/Settings/Settings';
import ResetPassword from './components/ResetPassword/ResetPassword';
import { StyledApp } from './App.styled';
import Home from './components/Home/Home';
import HomeFooter from './components/Footer/HomeFooter';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import Terms from './components/Policies/Terms';
import Features from './components/Features/Features';
import { useGoogleAPI } from './hooks/useGoogleAPI';
import About from './components/About/About';
import FAQ from './components/FAQ/FAQ';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PrescriptionRoutes } from './components/PrescriptionRoutes/PrescriptionRoutes';
import NotFound from './components/NotFound/NotFound';
import Banner from './components/utils/Banner/Banner';
import type { User } from 'firebase/auth';
import Bugsnag from '@bugsnag/js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase/config';

export type PageName =
  | 'form'
  | 'login'
  | 'signup'
  | 'scripts'
  | 'settings'
  | 'prescribers'
  | 'features'
  | 'home';

const App = () => {
  // Can use the user state to conditionally render or redirect routes (logged in vs out for example)
  const { user, authIsReady } = useAuthContext();
  const { loadGoogleAPI, googleLoaded } = useGoogleAPI();
  const { pathname } = useLocation();

  const [firstSignIn, setFirstSignIn] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  // Ensure the window is scrolled to the top when changing any routes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Only load google API services when the user is logged in
  useEffect(() => {
    if (user) {
      loadGoogleAPI();
    }
  }, [googleLoaded, loadGoogleAPI, user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const checkModalPreference = async () => {
      const userDocRef = doc(db, 'users', user.uid);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (!userData.hideBanner) {
            setIsBannerVisible(true);
          }
        }
      } catch (error) {
        console.error('Error getting user preferences:', error);
      }
    };

    checkModalPreference();
  }, [user]);

  const closeBanner = async (user: User) => {
    setIsBannerVisible(false);
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        hideBanner: true,
      });
      console.log('Banner preference updated successfully!');
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.error('Error updating banner preference:', error);
    }
  };

  return (
    <StyledApp className="App">
      <GlobalStyles />
      <Toaster position="bottom-center" />
      {authIsReady && (
        <>
          <Header />

          <Main isLoggedIn={user != null}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    {!user && <Home />}
                    {user && (
                      <>
                        <Banner isVisible={isBannerVisible} onClose={() => closeBanner(user)} />
                        <Dashboard
                          user={user}
                          firstSignIn={firstSignIn}
                          setFirstSignIn={setFirstSignIn}
                        />
                      </>
                    )}
                  </>
                }
              />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/terms-of-service" element={<Terms />} />

              <Route path="/features" element={<Features />} />

              <Route path="/faq" element={<FAQ />} />

              <Route path="/about" element={<About />} />

              <Route
                path="/dashboard"
                element={
                  <>
                    {!user && <Navigate to="/login" />}
                    {user && (
                      <>
                        <Banner isVisible={isBannerVisible} onClose={() => closeBanner(user)} />
                        <Dashboard
                          user={user}
                          firstSignIn={firstSignIn}
                          setFirstSignIn={setFirstSignIn}
                        />
                      </>
                    )}
                  </>
                }
              />

              <Route
                path="/settings"
                element={
                  <>
                    {!user && <Navigate to="/login" />}
                    {user && <Settings user={user} />}
                  </>
                }
              />

              <Route
                path="/prescription/*"
                element={<PrescriptionRoutes user={user} googleLoaded={googleLoaded} />}
              />

              <Route
                path="/signup"
                element={
                  <>
                    {!user && <Signup setFirstSignIn={setFirstSignIn} />}
                    {user && <Navigate to="/dashboard" />}
                  </>
                }
              />

              <Route
                path="/login"
                element={
                  <>
                    {!user && <Login />}
                    {user && <Navigate to="/dashboard" />}
                  </>
                }
              />

              <Route
                path="/edit-prescriber/:id"
                element={
                  <>
                    {user && <EditPrescriber googleLoaded={googleLoaded} user={user} />}
                    {!user && <Navigate to="/login" />}
                  </>
                }
              />

              <Route
                path="/add-prescriber"
                element={
                  <>
                    {user && <AddPrescriber googleLoaded={googleLoaded} user={user} />}
                    {!user && <Navigate to="/login" />}
                  </>
                }
              />

              <Route
                path="/prescribers"
                element={
                  <>
                    {user && <Prescribers user={user} />}
                    {!user && <Navigate to="/login" />}
                  </>
                }
              />

              <Route
                path="/scripts"
                element={
                  <>
                    {user && <Scripts user={user} />}
                    {!user && <Navigate to="/login" />}
                  </>
                }
              />

              <Route
                path="/scripts/:id"
                element={
                  <>
                    {user && <ViewScript user={user} />}
                    {!user && <Navigate to="/login" />}
                  </>
                }
              />

              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Main>
          {!user && <HomeFooter />}
        </>
      )}
    </StyledApp>
  );
};

export default App;
