import { ReactNode } from 'react';
import StyledFieldset from './Fieldset.styled';

type FieldsetProps = {
  className: string;
  legend: string;
  children: ReactNode;
};

const Fieldset = ({ className, legend, children }: FieldsetProps) => {
  return (
    <StyledFieldset className={className}>
      <legend className="visually-hidden">{legend}</legend>
      <span id="legend" className="legend-label">
        {legend}
      </span>
      <div className="fieldset-container">{children}</div>
    </StyledFieldset>
  );
};

export default Fieldset;
